import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { validateField } from "@utilities/invoiceInputValidation";

export const formatAmount = (amount: string): string => {
  var amountDecimal: number = parseFloat(amount.replace(/,/g, ""));
  return amountDecimal.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
};

export const formatPercents = (percent: number): string => {
  return percent.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 3 });
}

export const calculateTotal = (amount: string, quantity: string, discount: string = "", tax: string = ""): string => {
  var totalAmount: number = 0;
  if (amount && quantity && validateField("amount", amount) && validateField("quantity", quantity))
    totalAmount = parseFloat(amount.replace(/,/g, "")) * parseFloat(quantity);
  if (discount && validateField("amount", discount)) totalAmount -= parseFloat(discount);
  if (tax && validateField("taxRate", tax)) totalAmount += totalAmount * (parseFloat(tax) / 100);
  return totalAmount.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const calculateTotalList = (lineItemTotals, shippingAmount, shippingTax) => {
  let totalAmount = lineItemTotals.reduce((sum, value) => sum + value, 0);
  if (shippingAmount) {
    totalAmount += shippingAmount;
    if (shippingTax) {
      let shippingTaxToAdd = shippingAmount * (shippingTax / 100);
      totalAmount += shippingTaxToAdd;
    }
  }
  return totalAmount.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

export const formatDateFromApis = (dateString: string): string => {
  return new Date(dateString).toLocaleDateString('en-GB', {day : '2-digit', month: 'short', year: 'numeric'})
};

export const formatDateFromUI = (dateString: string): string => {
  const date = new Date(dateString);
  return format(date, "yyyy-MM-dd");
}

export const formatEmailCustomerID = (id: string): string => {
  return id.replace(/[.@]/g, "_");
}
