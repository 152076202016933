import { Link, Surface } from "@visa/nova-react";
import React, { useState } from "react";
import "@styles/header-styles.css";
import "@styles/dashboard-styles.css";
import * as Constants from '@constants/Constants';

export interface HeaderProps {
  title: string;
  environment: string;
  doDisplayGoLiveLink: boolean;
}

const SandboxBar = () => {
  return (
    <div>
      <div className="visa-banner-sandbox">
        <p className="visa-banner-sandbox-font"> {Constants.SANDBOX_BANNER_TEXT} <Link className="visa-banner-sandbox-link" href={Constants.SWITCH_TO_PRODUCTION_URL}> {Constants.SWITCH_TO_PRODUCTION_TEXT} </Link> </p>
      </div>
    </div>
  );
};

const AppHeader: React.FC<HeaderProps> = ({ title, environment, doDisplayGoLiveLink }) => {
  const isFirstOpen = localStorage.getItem(Constants.FIRST_OPEN_LOCAL_STORAGE)
  return (
    <div>
      <Surface className="v-flex v-gap-2 v-align-items-center">
        <p className="visa-header-title-font"> {title} </p>
        {environment && isFirstOpen ==='false' && <p className="visa-header-env-font"> - {environment} </p>}
      </Surface>
      {environment === 'Sandbox' && doDisplayGoLiveLink && isFirstOpen ==='false' && <SandboxBar/>}
    </div>
  );
};

export default AppHeader;
