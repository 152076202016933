import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Dashboard from "@taskpane/pages/Dashboard/Dashboard";
import SimpleInvoice from "@taskpane/pages/Dashboard/SimpleInvoice";
import ListInvoice from "@taskpane/pages/Dashboard/ListInvoice";
import More from "@taskpane/pages/Dashboard/More";
import Nav from "@visa/nova-react/nav";
import Tab from "@visa/nova-react/tab";
import Tabs from "@visa/nova-react/tabs";
import { Button } from "@visa/nova-react";
import DashboardIcon from "@assets/dashboard-icon.svg";
import SimpleInvoiceIcon from "@assets/Icon-Add-SimpleInvoice1.svg";
import ListInvoiceIcon from "@assets/Icon-Add-ListInvoice2.svg";
import { OptionsVerticalLow } from "@visa/nova-icons/react/visa";
import { ErrorBanner } from "@taskpane/pages/Banner/ErrorBanner";
import "@styles/dashboard-styles.css";
import { logout } from "@utilities/invoicingAPI";
import { setSignOutCallBack } from "@utilities/invoicingAPI";
import { failure } from "@utilities/invoiceSubmission";

const DashboardIconComponent = () => <img style={{ paddingBottom: 4 }} src={DashboardIcon} alt="dashboard" />;

const SimpleInvoiceIconComponent = () => (
  <img style={{ paddingTop: 7, paddingBottom: 4 }} src={SimpleInvoiceIcon} alt="create simple invoice" />
);

const ListInvoiceIconComponent = () => (
  <img style={{ paddingTop: 7, paddingBottom: 4 }} src={ListInvoiceIcon} alt="create list invoice" />
);



const DashboardContainer = ({ recipient, customerId, rootPath }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const initialTab = +new URLSearchParams(location.search).get("tab") || 0;
  const [pageIndex, setPageIndex] = useState(initialTab);

  const signOut = () => {
    new Promise(function (resolve, reject) {
      logout(resolve, reject);
    }).then(
      () => {navigate(rootPath + "environment")},
      (errorReason) => failure(errorReason)
    );
  }
  
  setSignOutCallBack(signOut)

  return (
    <div>
      <ErrorBanner />
      <div role="tablist">
        <Tabs className="visa-dashboard-tab-container">
          <Tab style={{ marginRight: 5 }}>
            <Button
              id="dashboard_home"
              className="visa-dashboard-tabs"
              stacked
              buttonSize="large"
              colorScheme="tertiary"
              element={
                <Link
                  to={rootPath + "dashboard"}
                  className={`${pageIndex === 0 ? "visa-dashboard-tabs-font-bold" : "visa-dashboard-tabs-font"}`}
                >
                  <DashboardIconComponent /> Dashboard
                </Link>
              }
              role="tab"
              aria-selected={pageIndex === 0}
              onClick={() => {
                setPageIndex(0);
                if (document.getElementById("error_banner_close")) {
                  document.getElementById("error_banner_close").click();
                }
              }}
            />
          </Tab>
          <Tab style={{ marginRight: 5 }}>
            <Button
              className="visa-dashboard-tabs"
              stacked
              buttonSize="large"
              colorScheme="tertiary"
              element={
                <Link
                  to="?tab=1"
                  className={`${pageIndex === 1 ? "visa-dashboard-tabs-font-bold" : "visa-dashboard-tabs-font"}`}
                >
                  <SimpleInvoiceIconComponent /> Create <br /> simple invoice
                </Link>
              }
              role="tab"
              aria-selected={pageIndex === 1}
              onClick={() => {
                setPageIndex(1);
                if (document.getElementById("error_banner_close")) {
                  document.getElementById("error_banner_close").click();
                }
              }}
            />
          </Tab>
          <Tab style={{ marginRight: 5 }}>
            <Button
              className="visa-dashboard-tabs"
              stacked
              buttonSize="large"
              colorScheme="tertiary"
              element={
                <Link
                  to="?tab=2"
                  className={`${pageIndex === 2 ? "visa-dashboard-tabs-font-bold" : "visa-dashboard-tabs-font"}`}
                >
                  <ListInvoiceIconComponent /> Create <br /> list invoice
                </Link>
              }
              role="tab"
              aria-selected={pageIndex === 2}
              onClick={() => {
                setPageIndex(2);
                if (document.getElementById("error_banner_close")) {
                  document.getElementById("error_banner_close").click();
                }
              }}
            />
          </Tab>
          <Tab>
            <Button
              style={{ width: 50 }}
              className="visa-dashboard-tabs"
              stacked
              buttonSize="large"
              colorScheme="tertiary"
              element={
                <Link
                  to="?tab=3"
                  className={`${pageIndex === 3 ? "visa-dashboard-tabs-font-bold" : "visa-dashboard-tabs-font"}`}
                >
                  <OptionsVerticalLow style={{ paddingBottom: 4 }} /> More
                </Link>
              }
              role="tab"
              aria-selected={pageIndex === 3}
              onClick={() => {
                setPageIndex(3);
                if (document.getElementById("error_banner_close")) {
                  document.getElementById("error_banner_close").click();
                }
              }}
            />
          </Tab>
        </Tabs>
        <div role="tabpanel">
          {pageIndex === 0 && <Dashboard recipient={recipient} customerId={customerId} rootPath={rootPath} />}
          {pageIndex === 1 && <SimpleInvoice rootPath={rootPath} customerId={customerId} />}
          {pageIndex === 2 && <ListInvoice rootPath={rootPath} customerId={customerId} />}
          {pageIndex === 3 && <More recipient={recipient} rootPath={rootPath} logOutHandler={signOut}/>}
        </div>
      </div>
    </div>
  );
};

export default DashboardContainer;
