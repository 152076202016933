import Surface from "@visa/nova-react/surface";
import EnvironmentSelect from "@taskpane/pages/SelectEnvironment/EnvironmentSelect";
import React, { useEffect } from "react";
import { Button, Divider } from "@visa/nova-react";
import "@taskpane/taskpane.css";
import * as Constants from "@constants/Constants";
import { useNavigate } from "react-router-dom";

const EnvironmentSelectionComponent = ({ rootPath, setGoLiveLinkVisibility }) => {
  const navigate = useNavigate();
  const handleClickSignIn = async() => {
    var selectedEnv = localStorage.getItem("environment");
    // Comment Out lines 16-23 to run locally
    if (selectedEnv === 'Production') {
      window.location.href = Constants.PRODUCTION_URL;
    } else if (selectedEnv === 'Sandbox') {
      window.location.href = Constants.SANDBOX_URL;
    }
    // Uncomment for running on localhost
    //navigate(rootPath + "dashboard");
  };

  const handleClickSignUp = () => {
    window.open(Constants.SANDBOX_SIGNUP_PAGE_URL, "_blank");
  };

  useEffect(() => {
    document.body.style.backgroundColor = Constants.WHITE_BACKGROUND_COLOR_CODE;
    setGoLiveLinkVisibility(false)
    return () => {
      document.body.style.backgroundColor = "";
    };
  }, []);

  return (
    <div>
      <Surface>
        <Surface>
          <img src={rootPath + "assets/Logo-VisaInvoice.png"} alt="Visa Invoice Logo" className="visa_logo_branding" />
        </Surface>
        <Surface>
          <Surface>{Constants.ENVIRONMENT_SELECTION_PAGE_LABEL_FIRST}</Surface>
          <Surface>{Constants.ENVIRONMENT_SELECTION_PAGE_LABEL_SECOND}</Surface>
        </Surface>
        <Surface>
          <Surface>
            <EnvironmentSelect></EnvironmentSelect>
          </Surface>
        </Surface>
        <Surface style={{ paddingBottom: "30px" }}>
          <Surface>
            <Button buttonSize="large" onClick={handleClickSignIn} className="sign_in_to_visa_invoice">
              {Constants.ENVIRONMENT_SELECTION_PAGE_SINGNIN_BUTTON_TEXT}
            </Button>
          </Surface>
        </Surface>
        <Divider></Divider>
      </Surface>
      <Surface style={{ paddingLeft: "20px", paddingRight: "20px" }}>
        <Surface>
          <p className="visa-new-customer-font"> {Constants.ENVIRONMENT_SELECTION_PAGE_FOOTER_LABEL}</p>
        </Surface>
        <Surface>
          <Button
            buttonSize="large"
            onClick={handleClickSignUp}
            colorScheme="secondary"
            className="create_an_account_at_visa_invoice"
          >
            {Constants.ENVIRONMENT_SELECTION_PAGE_SIGNUP_BUTTON_TEXT}{" "}
          </Button>
        </Surface>
      </Surface>
      <Surface />
      <Surface />
      <Surface />
      <Surface />
      <Surface />
    </div>
  );
};

export default EnvironmentSelectionComponent;
