export const validateField = (fieldName: string, input: string) => {
  const regex = {
    email: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]{2,}$/,
    amount: /^(\d{1,3}(,?\d{3})*(\.\d{0,2})?)?$/,
    shippingAmount: /^(\d{1,3}(,?\d{3})*(\.\d{0,2})?)?$/,
    invoiceNumber: /^[a-zA-Z0-9_-]*$/,
    shippingTax: /^(\d+(\.\d{1,3})?)?$/,
    quantity: /^(\d+)?$/,
    taxRate: /^(\d+(\.\d{1,3})?)?$/,
    discountPercent: /^(\d+(\.\d{1,3})?)?$/,
    discountAmount: /^(\d{1,3}(,?\d{3})*(\.\d{0,2})?)?$/,
  };
  return regex[fieldName].test(input);
};