import { ChevronDownTiny, ErrorTiny } from '@visa/nova-icons/react/visa';
import { ChangeEvent, useRef, useState } from 'react';
import * as Constants from '@constants/Constants';
import InputContainer from '@visa/nova-react/input-container';
import InputControl from '@visa/nova-react/input-control';
import Label from '@visa/nova-react/label';
import Select from '@visa/nova-react/select';
import React from 'react';
import { EnvironmentTypes } from '@constants/environmentEnum';

const environmentSelectComponent = () => {
  const [stateOne, setStateOne] = useState({
    touched: false,
    valid: false,
    validated: false,
    value: localStorage.getItem('environment')
  });
  localStorage.setItem('environment', stateOne.value);

  const refOneValidation = useRef<HTMLSelectElement>(null);

  const handleStateOne = (e: ChangeEvent<HTMLSelectElement>) => {
    setStateOne({
      ...stateOne,
      touched: true,
      validated: false,
      value: e.target.value
    });
    localStorage.setItem('environment', e.target.value);
    window.dispatchEvent(new Event("storage"));
  };

  return (
    <>
      <fieldset className="v-flex v-flex-col v-gap-6" aria-labelledby="select-error-message">
        <Label>{Constants.ENVIRONMENT_SELECTION_LABEL} &nbsp; </Label>
        <InputContainer>
          <Select
            id="select-environment-field"
            name="full-name"
            aria-invalid={stateOne.validated && !stateOne.valid}
            aria-describedby="select-environment-message"
            onChange={handleStateOne}
            ref={refOneValidation}
            value={stateOne.value}
          >
            <option value="" disabled hidden />
            <option value={EnvironmentTypes.Sandbox}>Sandbox</option>
            <option value={EnvironmentTypes.Production}>Production</option>
          </Select>
          <InputControl>
            <ChevronDownTiny />
          </InputControl>
        </InputContainer>
      </fieldset>
    </>
  );
};




export default environmentSelectComponent;



