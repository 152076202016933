import React, { createContext, useContext, useState } from "react";

interface SharedCreatedInvoiceContextType {
    invoice;
    setInvoice: React.Dispatch<React.SetStateAction<object>>;
}

export const SharedCreatedInvoiceContext = createContext<SharedCreatedInvoiceContextType | undefined>(undefined);

export const SharedCreatedInvoiceProvider: React.FC = ({ children }) => {
    const [invoice, setInvoice] = useState<object | null>(null);

    return (
        <SharedCreatedInvoiceContext.Provider value={{ invoice, setInvoice }}>
            {children}
        </SharedCreatedInvoiceContext.Provider>
    )
}

export const useInvoice = () => {
    const context = useContext(SharedCreatedInvoiceContext);
    return context;
}