import * as React from "react";
import Dialog from "@visa/nova-react/dialog";
import DialogContent from "@visa/nova-react/dialog-content";
import DialogHeader from "@visa/nova-react/dialog-header";
import DialogIcon from "@visa/nova-react/dialog-icon";
import * as Constants from "@constants/Constants";
import { Button, DialogCloseButton, Surface } from "@visa/nova-react";

export const ErrorDialog = ({ headerText, contentText, type, errorDialogRef }) => {
  return (
    <div>
      <Dialog ref={errorDialogRef} id="error-dialog" messageType={type}>
        <DialogContent>
          <DialogHeader>
            <DialogIcon />
            {headerText}
          </DialogHeader>
          <p>{contentText}</p>
          <Surface/>
            <div className="visa_logout_dialog_div">
                <Button className="visa_logout_confirmation_button" buttonSize="large" onClick={() => errorDialogRef.current?.close()}>{Constants.ERROR_DIALOG_BUTTON_TEXT}</Button>
            </div>
        </DialogContent>
        <DialogCloseButton onClick={() => errorDialogRef.current?.close()} />
      </Dialog>
    </div>
  );
};