import React from "react";
import ListInvoiceComponent from "@taskpane/components/ListInvoiceComponent";
import "@styles/dashboard-styles.css";

const ListInvoice = ({rootPath, customerId}) => {
  return (
    <div>
      <div className="visa-dashboard-spacer" />
      <div className="visa-banner-dashboard">
        <p className="visa-banner-dashboard-font"> *Required Fields </p>
      </div>
      <div className="visa-dashboard-spacer" />
      <div className="visa-invoice-styling">
        <ListInvoiceComponent customerId={customerId} rootPath={rootPath} />
      </div>
    </div>
  );
};

export default ListInvoice;
