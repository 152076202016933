import App from "@taskpane/components/App";
import { initializeIcons } from "@fluentui/font-icons-mdl2";
import { ThemeProvider } from "@fluentui/react";
import * as React from "react";
import * as ReactDOM from "react-dom";
import "@visa/vpds-styles/styles.css"
import "@visa/vpds-styles/themes/nova/index.css"
import { StrictMode } from "react";
import { BrowserRouter } from "react-router-dom";
import { createRoot } from 'react-dom/client';

/* global document, Office, module, require */

initializeIcons();
const container = document.getElementById("container")
const root = createRoot(container)
let isOfficeInitialized = false;

const title = "Visa Cybersource Invoice Add-in";

/*StrictMode currently helps with:
  Identifying components with unsafe lifecycles
  Warning about legacy string ref API usage
  Warning about deprecated findDOMNode usage
  Detecting unexpected side effects
  Detecting legacy context API
  Ensuring reusable state

  BrowserRouter stores the current location in the browser's
  address bar using clean URLs and navigates using the
  browser's built-in history stack.
  */

const render = (Component) => {
  root.render(
  <StrictMode>
      <BrowserRouter>
        <ThemeProvider>
          <Component title={title} isOfficeInitialized={isOfficeInitialized} />
        </ThemeProvider>
      </BrowserRouter>
    </StrictMode>
  );
};

delete history.pushState;
delete history.replaceState;

/* Render application after Office initializes */
Office.onReady(() => {
  isOfficeInitialized = true;
  render(App);
});