import React, { useEffect, useRef, useState } from "react";
import { Nav, Button, Tab, Tabs } from "@visa/nova-react";
import * as Constants from "@constants/Constants";
import "@styles/dashboard-styles.css";
import Cookies from "js-cookie";
import { SignoutDialog } from "@taskpane/components/SignoutDialog";

const OptionsComponent = ({ rootPath, logOutHandler }) => {
const [pageIndex, setPageIndex] = useState(null);
  useEffect(() => {
    document.body.style.backgroundColor = Constants.WHITE_BACKGROUND_COLOR_CODE;
    return () => {
      document.body.style.backgroundColor = "";
    };
  }, []);

  const ref = useRef<HTMLDialogElement>()
  
  const showLogoutDialog = () => {
    ref.current?.showModal()
  }

  const loadInstructions = () => {
    if (Office.context.mailbox != null) {
      Office.context.ui.displayDialogAsync(
        Constants.INVOICING_API_URL + "instructions",
        { height: 100, width: (965 / 1095) * 100 },
        function (asyncResult) {
          if (asyncResult.status === Office.AsyncResultStatus.Failed) {
            console.error("Failed to open instructions dialog: " + asyncResult.error.message);
          } else {
            var dialog = asyncResult.value;
            let dialogEventReceived = false;
            dialog.addEventHandler(Office.EventType.DialogEventReceived, function (arg) {
              if (!dialogEventReceived) {
                dialogEventReceived = true;
              }
            });
          }
        }
      );
    } else window.open(rootPath + "instructions", "_blank", "width=965,height=1095");
  };

  return (
    <><SignoutDialog submit ={logOutHandler} dialogRef={ref}></SignoutDialog><div role="tablist">
      <Nav orientation="vertical" className="visa-more-padding">
        <nav>
          <Tabs orientation="vertical" role="tablist">
            <Tab>
              <Button
                buttonSize="large"
                colorScheme="tertiary"
                element={<p className="visa-options-text"> Information </p>}
                aria-selected={pageIndex === 0}
                onClick={() => {
                  setPageIndex(0);
                  loadInstructions();
                } } />
            </Tab>
            <Tab>
              <Button
                buttonSize="large"
                colorScheme="tertiary"
                element={<p className="visa-options-text"> Sign out </p>}
                aria-selected={pageIndex === 1}
                onClick={() => {
                  showLogoutDialog();
                  setPageIndex(1);
                } } />
            </Tab>
          </Tabs>
        </nav>
      </Nav>
      <div role="tabpanel"></div>
    </div></>
  );
};

export default OptionsComponent;

