import { QuestionLow as QuestionLow } from "@visa/nova-icons/react/generic";
import { Tooltip } from "@visa/nova-react";
import React, { useState } from "react";
import * as Constants from "@constants/Constants";
import { useDismiss, useFloating, useFocus, useHover, useInteractions, useRole } from "@floating-ui/react";
import "@styles/dashboard-styles.css";

export const iconComponent = () => {
  const [isOpen, setIsOpen] = useState(false);

  const { x, y, strategy, refs, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: "right",
  });

  const hover = useHover(context, { move: false });
  const focus = useFocus(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: "tooltip" });

  const { getReferenceProps, getFloatingProps } = useInteractions([hover, focus, dismiss, role]);

  return (
    <>
      <QuestionLow className="visa-icons-size" ref={refs.setReference} {...getReferenceProps()}></QuestionLow>
      {isOpen && (
        <Tooltip
          ref={refs.setFloating}
          style={{
            position: strategy,
            top: (y ?? 0) + 25,
            left: (x ?? 0) + 10,
            width: '150px',
            height: '66px',
            whiteSpace: "normal",
            wordWrap: "break-word", 
            zIndex: 1000,
            fontSize: '12px',
            fontFamily: "'Segoe UI', sans-serif",
          }}
          {...getFloatingProps()}
        >
          {Constants.DESCRIPTION_INFOICON_TOOLTIP_TEXT}
        </Tooltip>
      )}
    </>
  );
};

export default iconComponent;
