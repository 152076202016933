export const TEST_RECIPIENT_NAME: string = "Visa Support"
export const TEST_RECIPIENT_EMAIL: string = "GDLOutlookInvoicing@visa.com"
export const ENVIRONMENT_LOCAL_STORAGE: string = "environment"
export const FIRST_OPEN_LOCAL_STORAGE: string = "first_open"
export const AUTHENTICATED: string = "isAuthenticated"
export const NO_DATA_FOUND_STATUS: string = "NOTFOUND"
export const NO_RECIPIENTS_ERROR_HEADER: string = "Missing address"
export const PLATFORM_NOT_SUPPORTED_HEADER: string = "Add-in Error"
export const NO_RECIPIENTS_ERROR_TEXT: string = "To use this add-in you must have an email recipient. Please add a recipient address to the ‘To’ field and relaunch the add-in."
export const PLATFORM_NOT_SUPPORTED_TEXT: string = "Sorry, this add-in is currently not supported on Outlook on the web. To use this add-in, open Microsoft Outlook on your computer."
export const LOGOUT_BUTTON_TEXT: string = "Ok"
export const LOGOUT_FAILED_TEXT: string = "Logout failed. Please try again."
export const ERROR_DIALOG_BUTTON_TEXT: string = "Ok"
export const LOGOUT_DIALOG_TEXT: string = "To finish signing out please close Outlook."
export const LOGOUT_DIALOG_HEADER: string = "Sign out"
export const VISA_TITLE: string = "Visa Acceptance Solutions Invoicing"
export const VISA_WELCOME_PAGE_TITLE: string = "Welcome"
export const VISA_WELCOME_PAGE_HEADING: string = "Turn Outlook into your invoicing hub!"
export const VISA_WELCOME_PAGE_LABEL: string = "Send invoices directly from Outlook and provide your customers a secure way to pay."
export const VISA_WELCOME_PAGE_GET_STARTED_BUTTON_LABEL: string = "Get Started"
export const ENVIRONMENT_SELECTION_PAGE_LABEL_FIRST: string = "Please sign in using your Cybersource or Visa Acceptance Solutions account."
export const ENVIRONMENT_SELECTION_PAGE_LABEL_SECOND: string = "Choose either the sandbox or production environment based on your account configuration."
export const ENVIRONMENT_SELECTION_PAGE_FOOTER_LABEL: string = "New Customer?"
export const ENVIRONMENT_SELECTION_PAGE_SINGNIN_BUTTON_TEXT: string = "Sign in to your account"
export const ENVIRONMENT_SELECTION_PAGE_SIGNUP_BUTTON_TEXT: string = "Create an account"
export const ENVIRONMENT_SELECTION_LABEL: string = "Environment"
export const INVOICE_CUSTOMER_NAME_LABEL: string = "Customer name"
export const INVOICE_CUSTOMER_EMAIL_LABEL: string = "Customer email"
export const INVOICE_NUMBER_LABEL: string = "Invoice number"
export const INVOICE_DESCRIPTION_LABEL: string = "Description"
export const INVOICE_AMOUNT_LABEL: string = "Amount"
export const INVOICE_QUANTITY_LABEL: string = "Quantity"
export const INVOICE_DUE_DATE_LABEL: string = "Invoice due date"
export const INVOICE_SHIPPING_LABEL: string = "Shipping"
export const INVOICE_SHIPPING_TAX_LABEL: string = "Shipping tax"
export const INVOICE_ITEM_NAME_LABEL: string = "Item name"
export const INVOICE_DISCOUNT_LABEL: string = "Discount"
export const INVOICE_TAX_LABEL: string = "Tax"
export const INVOICE_INSERT_BUTTON_TEXT: string = "Insert invoice link"
export const INVOICE_DRAFT_BUTTON_TEXT: string = "Save draft"
export const INVOICE_UPDATE_DRAFT_BUTTON_TEXT: string = "Update draft"
export const INVOICE_ERROR_EMPTY_TEXT: string = " is required"
export const INVOICE_ERROR_INVALID_TEXT: string = " is invalid"
export const INVOICE_ERROR_NAME_LENGTH_EXCEEDED: string = "Customer name must be 100 characters or less"
export const INVOICE_ERROR_EMAIL_LENGTH_EXCEEDED: string = "Customer email must be 255 characters or less"
export const INVOICE_ERROR_LINE_ITEM_NAME_LENGTH_EXCEEDED: string = "Item Name must be 255 characters or less"
export const INVOICE_ERROR_AMOUNT_LIMIT_EXCEEDED: string = "Amount must be less than 100,000,000,000,000"
export const INVOICE_ERROR_SHIPPING_LIMIT_EXCEEDED: string = "Shipping must be less than 100,000,000,000,000"
export const INVOICE_ERROR_TOTAL_LIMIT_EXCEEDED: string = "Total Due must be less than 100,000,000,000,000"
export const INVOICE_ERROR_QUANTITY_LIMIT_EXCEEDED: string = "Quantity must be less than 100,000"
export const INVOICE_ERROR_PERCENT_TEXT: string = "Must be less than 100%"
export const INVOICE_ERROR_DECIMALS_TEXT: string = "Must be positive, supports 3 decimals."
export const INVOICE_ERROR_DECIMALS_TEXT_DISCOUNT: string = "Must be positive, supports 2 decimals."
export const INVOICE_ERROR_GREATER_THAN_TOTAL: string = "Must be less than or equal to "
export const INVOICE_ERROR_CHAR_LIMIT_EXCEEDED: string = "Description must be 400 characters or less"
export const INVOICE_ERROR_ID_LIMIT_EXCEEDED: string = "Invoice Number must be 20 characters or less"
export const INVOICE_ERROR_ID_INVALID_CHARS: string = "Invoice number allows numbers, letters, and special characters: '_' and '-'"
export const INVOICE_DESCRIPTION_PLACEHOLDER_TEXT: string = "e.g. PO# 12345: Item description"
export const INVOICE_TOTAL_DUE: string = "Total due"
export const INVOICING_API_URL: string = process.env.INVOICING_API_URL;
export const CLIENT_ID_SBX: string = process.env.CLIENT_ID_SBX;
export const CLIENT_ID_PROD: string = process.env.CLIENT_ID_PROD;
export const REDIRECT_URL: string = process.env.REDIRECT_URL;
export const PRODUCTION_URL: string = `https://businesscenter.visaacceptance.com/ebc2/oauth/authorize?sub=oauth&redirect_url=${REDIRECT_URL}&client_id=${CLIENT_ID_PROD}&state=stateValue`
export const SANDBOX_URL: string = `https://businesscentertest.visaacceptance.com/ebc2/oauth/authorize?sub=oauth&redirect_url=${REDIRECT_URL}&client_id=${CLIENT_ID_SBX}&state=stateValue`
export const SANDBOX_SIGNUP_PAGE_URL: string = "https://developer.visaacceptance.com/hello-world/msft-sandbox.html?psid=3VY6AWZZ"
export const DASBOARD_TABLE_NO_DATA: string = "Click 'Create Simple Invoice' for a single-item invoice or 'Create List Invoice' for a multiple-item invoice."
export const DASHBOARD_DATA_ERROR: string = "There was an error retrieving invoices. Please try again."
export const DETAILS_DATA_ERROR: string = "There was an error retrieving invoice details. Please try again."
export const IDENTITY_TOKEN_FETCH_ERROR: string = "There seems some issue connecting to Outlook Server. Check your conection."
export const DESCRIPTION_INFOICON_TOOLTIP_TEXT: string = "This will be displayed to your customer on the payment page"
export const SANDBOX_BANNER_TEXT: string = "This is a Sandbox environment."
export const SWITCH_TO_PRODUCTION_TEXT: string = "Request to Go Live"
export const SWITCH_TO_PRODUCTION_URL: string = "https://support.visaacceptance.com/knowledgebase/Knowledgearticle/?code=000002034"
export const BACK_TO_DASHBOARD_TEXT: string = "Back to dashboard"
export const EDIT_TEXT: string = "Edit"
export const WHITE_BACKGROUND_COLOR_CODE: string = "#ffffff"
export const BLACK_UNPAID_COLOR_CODE: string = "#000000"
export const GREEN_PAID_COLOR_CODE: string = "#40996b"
export const RED_OVERDUE_COLOR_CODE: string = "#be2d2d"
export const BLUE_DRAFT_COLOR_CODE: string = "#1434cb"
export const BLUE_PRIMARY_COLOR: string = "#1435CB !important"
export const INVOICE_CONFIRMATION_TOP_TEXT: string = "Your invoice link has been inserted into your email."
export const INVOICE_CONFIRMATION_BOTTOM_TEXT: string = "To view or edit your invoice, click on the invoice number in the dashboard."
export const GO_TO_DASHBOARD_FROM_CONFIRMATION_TEXT: string = "Go to dashboard"
export const INVOICE_LIST_ADD_ANOTHER_ITEM: string = "Add another item"
export const PREFIX_UNIQUE_INVOICE_NUMBER: string = "otlk_"
export const PAY_INVOICE_EMAIL_TEXT: string = '<br><br>Here is your invoice for the order your placed.'
export const PAY_HREF_PRODUCTION: string = 'Pay Invoice </a>'
export const PAY_HREF_SANDBOX: string = 'Pay Test Invoice </a>'
export const PAY_INSTRUCTION_PRODUCTION: string = "<br><br>Instructions are found <a href='https://developer.visaacceptance.com/hello-world/testing-guide-v1.html'>here.</a>"
export const PAY_INSTRUCTION_SANDBOX: string = "<br>To pay a test invoice in Sandbox environment you must use test credit card.<br><br>Instructions are found <a href='https://developer.visaacceptance.com/hello-world/testing-guide-v1.html'>here.</a>"
export const IDENTITY_TOKEN_RETRIEVAL_ERROR: string = "Token retrieval failed with message:"