import React, { createContext, useContext, useState } from "react";

interface SharedInvoiceContextType {
  draftInvoice: object;
  setDraftInvoice: React.Dispatch<React.SetStateAction<object>>;
}

export const SharedInvoiceStateContext = createContext<SharedInvoiceContextType | undefined>(undefined);

export const SharedInvoiceStateProvider: React.FC = ({ children }) => {
  const [draftInvoice, setDraftInvoice] = useState({ simpleInvoice: {}, listInvoice: { inputFields: {}, lineItemStates: [] } });

  return <SharedInvoiceStateContext.Provider value={{ draftInvoice, setDraftInvoice }}>{children}</SharedInvoiceStateContext.Provider>;
};

export const useDraftInvoice = () => {
  const context = useContext(SharedInvoiceStateContext);
  return context;
};
