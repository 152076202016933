import React from "react";
import "@styles/loading-styles.css";

const LoadingComponent = ({ text }) => {
  return (
    <div className="loading-container">
      <div className="loader"></div>
      {text && <p className="loading-text"> {text} </p>}
    </div>
  );
};

export default LoadingComponent;
