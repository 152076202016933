import React from "react";
import InvoiceTable from "@taskpane/components/InvoiceTable";
import "@styles/dashboard-styles.css";
import { formatEmailCustomerID } from "@utilities/textFormat";

const Dashboard = ({ recipient, customerId, rootPath }) => {

  return (
    <div>
      <div className="visa-dashboard-spacer" />
      <div className="visa-banner-dashboard">
        <p className="visa-banner-dashboard-font"> Invoices for {recipient} </p>
      </div>
      <div className="visa-dashboard-spacer" />
      <div>
        <InvoiceTable customerId={formatEmailCustomerID(customerId)} rootPath={rootPath}/>
      </div>
    </div>
  );
};

export default Dashboard;
