import * as React from "react";
import Dialog from "@visa/nova-react/dialog";
import DialogContent from "@visa/nova-react/dialog-content";
import DialogHeader from "@visa/nova-react/dialog-header";
import * as Constants from "@constants/Constants";
import { Button, DialogCloseButton, Surface } from "@visa/nova-react";
import "@styles/dialog-styles.css"; 


export const SignoutDialog = ({ dialogRef, submit }) => {

  return (
    <div>
      <Dialog ref={dialogRef} id="signout-dialog">
        <DialogContent >
          <DialogHeader>
            {Constants.LOGOUT_DIALOG_HEADER}
          </DialogHeader>
            <p>{Constants.LOGOUT_DIALOG_TEXT}</p>
            <Surface/>
            <div className="visa_logout_dialog_div">
                <Button className="visa_logout_confirmation_button" buttonSize="large" onClick={() => submit()}>{Constants.LOGOUT_BUTTON_TEXT}</Button>
            </div>
        </DialogContent>
        <DialogCloseButton onClick={() => dialogRef.current?.close()} />
      </Dialog>
    </div>
  );
};
