import React, { useState, useEffect, useRef } from "react";
import { Button, Input, InputContainer, InputMessage, Label, Textarea } from "@visa/nova-react";
import TextareaAutosize from "react-textarea-autosize";
import "@styles/dashboard-styles.css";
import * as Constants from "@constants/Constants";
import * as Limits from "@constants/InputLimits";
import InfoIcon from "@taskpane/components/InfoIcon";
import InputError from "@taskpane/components/InputError";
import { CalendarTiny } from "@visa/nova-icons/react/visa";
import { ClearAltTiny } from "@visa/nova-icons/react/generic";
import { validateField } from "@utilities/invoiceInputValidation";
import { formatAmount, calculateTotalList } from "@utilities/textFormat";
import { useDetails } from "@taskpane/components/SharedDetailsContext";
import { useNavigate } from "react-router-dom";
import { createInvoice, updateInvoice } from "@utilities/invoicingAPI";
import { useInvoice } from "@taskpane/components/SharedCreatedInvoiceContext";
import { useDraftInvoice } from "@taskpane/components/SharedInvoiceStateContext";
import LineItem from "@taskpane/components/LineItem";
import { AddAltTiny } from "@visa/nova-icons/react/visa";
import DatePicker from "react-datepicker";
import { format, parse, isValid } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import { utcToZonedTime } from "date-fns-tz";
import { nanoid } from "nanoid";
import { success, failure } from "@utilities/invoiceSubmission";

const ListInvoiceComponent = ({ rootPath, customerId }) => {
  const { details, setDetails } = useDetails();
  const { draftInvoice, setDraftInvoice } = useDraftInvoice();
  const haveDetails = Object.keys(details["listInvoice"]).length > 0;
  const haveDraft =
    Object.keys(draftInvoice["listInvoice"]["inputFields"]).length > 0 ||
    draftInvoice["listInvoice"]["lineItemStates"].length > 0;
  const { setInvoice } = useInvoice();
  const navigate = useNavigate();
  const date = new Date();
  const [inputFields, setInputFields] = useState({
    name: haveDetails
      ? details["listInvoice"]["customerName"]
      : haveDraft
      ? draftInvoice["listInvoice"]["inputFields"]["name"]
      : "",
    email: haveDetails
      ? details["listInvoice"]["customerEmail"]
      : haveDraft
      ? draftInvoice["listInvoice"]["inputFields"]["email"]
      : customerId,
    invoiceNumber: haveDetails
      ? details["listInvoice"]["invoiceId"]
      : Constants.PREFIX_UNIQUE_INVOICE_NUMBER +
        date.getFullYear() +
        date.getMonth() +
        date.getDate() +
        date.getHours() +
        date.getMinutes() +
        date.getSeconds(),
    description: haveDetails
      ? details["listInvoice"]["description"]
      : haveDraft
      ? draftInvoice["listInvoice"]["inputFields"]["description"]
      : "",
    dueDate: haveDetails ? details["listInvoice"]["dueDate"] : format(date, "dd MMM yyyy"),
    shippingAmount: haveDetails
      ? details["listInvoice"]["shippingAmount"]
      : haveDraft
      ? draftInvoice["listInvoice"]["inputFields"]["shippingAmount"]
      : "",
    shippingTax: haveDetails
      ? details["listInvoice"]["shippingTax"]
      : haveDraft
      ? draftInvoice["listInvoice"]["inputFields"]["shippingTax"]
      : "",
    totalAmount: haveDetails
      ? details["listInvoice"]["totalAmount"]
      : haveDraft
      ? draftInvoice["listInvoice"]["inputFields"]["totalAmount"]
      : "",
    invoiceMode: haveDetails ? details["listInvoice"]["status"] : "DRAFT",
  });
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const inputToLabelMap = {
    name: Constants.INVOICE_CUSTOMER_NAME_LABEL,
    email: Constants.INVOICE_CUSTOMER_EMAIL_LABEL,
    invoiceNumber: Constants.INVOICE_NUMBER_LABEL,
    description: Constants.INVOICE_DESCRIPTION_LABEL,
    dueDate: Constants.INVOICE_DUE_DATE_LABEL,
    shippingAmount: Constants.INVOICE_SHIPPING_LABEL,
  };
  const [lineItemStates, setLineItemStates] = useState(
    haveDetails
      ? details["listInvoice"]["lineItems"].map((item) => ({ ...item, id: nanoid() }))
      : haveDraft && draftInvoice["listInvoice"]["lineItemStates"].length > 0
      ? draftInvoice["listInvoice"]["lineItemStates"].map((item) => ({ ...item, id: nanoid() }))
      : [
          {
            id: nanoid(),
            productName: "",
            amount: "",
            quantity: "",
            discountAmount: "",
            discountPercent: "",
            taxAmount: "",
            taxRate: "",
            totalAmount: "0.00",
          },
        ]
  );
  const [lineItemErrorStates, setLineItemErrorStates] = useState([{}]);
  const [isItemAdded, setIsItemAdded] = useState(false);
  const [errors, setErrors] = useState({});
  const endOfLineItemRef = useRef(null);
  const childRefs = useRef(new Array(lineItemStates.length).fill(null));

  useEffect(() => {
    return () => {
      setDetails((prevState) => ({ ...prevState, ["listInvoice"]: {} }));
    };
  }, []);

  useEffect(() => {
    setInputFields((prev) => ({
      ...prev,
      totalAmount: calculateTotalList(
        lineItemStates.map((lineItem) => {
          if (lineItem.totalAmount) return parseFloat(lineItem.totalAmount.replace(/,/g, ""));
          else return parseFloat(lineItem.totalAmount);
        }),
        parseFloat(inputFields.shippingAmount.replace(/,/g, "")),
        parseFloat(inputFields.shippingTax)
      ),
    }));
  }, [lineItemStates, inputFields.shippingAmount, inputFields.shippingTax]);

  useEffect(() => {
    if (!haveDetails) {
      let { invoiceNumber, dueDate, ...savedInputFields } = inputFields;
      setDraftInvoice((prevState) => ({
        ...prevState,
        ["listInvoice"]: {
          inputFields: savedInputFields,
          lineItemStates: lineItemStates,
        },
      }));
    }
  }, [inputFields, lineItemStates]);

  useEffect(() => {
    if (lineItemStates.length > 1 && isItemAdded) {
      document.getElementById(`new-component-input-${lineItemStates.length}`).focus();
      endOfLineItemRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [lineItemStates.length, isItemAdded]);

  function updateDetails(index, newDetails) {
    setLineItemStates((prevDetails) =>
      prevDetails.map((details, i) => {
        if (i === index) {
          return {
            id: details.id,
            ...newDetails,
          };
        }
        return details;
      })
    );
  }

  function updateLineItemErrors(index, newError) {
    setLineItemErrorStates((prevErrors) => prevErrors.map((error, i) => (i === index ? newError : error)));
  }

  function deleteLineItem(deletionIndex) {
    childRefs.current = childRefs.current.filter((_, i) => i != deletionIndex);
    setLineItemStates((prevItems) => prevItems.filter((_, index) => deletionIndex != index));
    setLineItemErrorStates((prevErrors) => prevErrors.filter((_, index) => deletionIndex != index));
    setIsItemAdded(false);
  }

  function addComponent() {
    setLineItemStates((prevData) => [
      ...prevData,
      {
        id: nanoid(),
        productName: "",
        amount: "",
        quantity: "",
        discountAmount: "",
        discountPercent: "",
        taxAmount: "",
        taxRate: "",
        totalAmount: "0.00",
      },
    ]);
    setLineItemErrorStates((prevErrors) => [...prevErrors, null]);
    setIsItemAdded(true);
  }

  function handleChange(e, fieldName: string) {
    const newText = e.target.value;
    setInputFields((prevState) => {
      const updatedState = { ...prevState, [fieldName]: newText };
      return updatedState;
    });
  }

  function handleBlurValidate(fieldName: string) {
    return new Promise((resolve) => {
      let errorMessage = "";

      if (fieldName != "shippingAmount" && fieldName != "shippingTax" && !inputFields[fieldName]) {
        errorMessage = inputToLabelMap[fieldName] + Constants.INVOICE_ERROR_EMPTY_TEXT;
      } else {
        if (fieldName === "name" && inputFields[fieldName].length > Limits.CUSTOMER_NAME_LENGTH_LIMIT)
          errorMessage = Constants.INVOICE_ERROR_NAME_LENGTH_EXCEEDED;
        else if (fieldName === "shippingAmount") {
          if (!validateField(fieldName, inputFields[fieldName].replace(/,/g, ""))) {
            errorMessage = inputToLabelMap[fieldName] + Constants.INVOICE_ERROR_INVALID_TEXT;
          } else if (Number(inputFields[fieldName].replace(/,/g, "")) > Limits.FLOAT_LIMIT) {
            errorMessage = Constants.INVOICE_ERROR_SHIPPING_LIMIT_EXCEEDED;
          }
        } else if (fieldName === "email") {
          if (!validateField(fieldName, inputFields[fieldName])) {
            errorMessage = inputToLabelMap[fieldName] + Constants.INVOICE_ERROR_INVALID_TEXT;
          } else if (inputFields[fieldName].length > Limits.CUSTOMER_EMAIL_LENGTH_LIMIT) {
            errorMessage = Constants.INVOICE_ERROR_EMAIL_LENGTH_EXCEEDED;
          }
        } else if (fieldName === "invoiceNumber") {
          if (inputFields[fieldName].length > Limits.INVOICE_NUMBER_LENGTH_LIMIT)
            errorMessage = Constants.INVOICE_ERROR_ID_LIMIT_EXCEEDED;
          else if (!validateField(fieldName, inputFields[fieldName]))
            errorMessage = Constants.INVOICE_ERROR_ID_INVALID_CHARS;
        } else if (fieldName === "description" && inputFields.description.length > Limits.DESCRIPTION_LENGTH_LIMIT) {
          errorMessage = Constants.INVOICE_ERROR_CHAR_LIMIT_EXCEEDED;
        } else if (fieldName === "shippingTax") {
          if (!validateField(fieldName, inputFields[fieldName])) {
            errorMessage = Constants.INVOICE_ERROR_DECIMALS_TEXT;
          } else if (Number(inputFields[fieldName]) > Limits.PERCENT_LIMIT) {
            errorMessage = Constants.INVOICE_ERROR_PERCENT_TEXT;
          }
        } else if (fieldName === "dueDate") {
          if (!isValid(parse(inputFields[fieldName], "dd MMM yyyy", new Date()))) {
            setInputFields({ ...inputFields, [fieldName]: "" });
          }
        }
      }

      setErrors((prevState) => ({ ...prevState, [fieldName]: errorMessage }));
      resolve(errorMessage);
    });
  }

  function handleBlurFormat(fieldName: string, input: string) {
    handleBlurValidate(fieldName).then((errorMessage) => {
      if (errorMessage === "" && input != "") {
        setInputFields({ ...inputFields, [fieldName]: formatAmount(input) });
      }
    });
  }

  function createDraft(inputFields) {
    new Promise(function (resolve, reject) {
      createInvoice(inputFields, lineItemStates, resolve, reject);
    }).then(
      () => success(setInvoice, setDraftInvoice, "", [], [], "", navigate, false),
      (errorReason) => failure(errorReason)
    );
  }

  function updateDraft(inputFields) {
    new Promise(function (resolve, reject) {
      updateInvoice(inputFields, lineItemStates, resolve, reject);
    }).then(
      () => success(setInvoice, setDraftInvoice, "", [], [], "", navigate, false),
      (errorReason) => failure(errorReason)
    );
  }

  function createNewInvoice(inputFields) {
    new Promise(function (resolve, reject) {
      createInvoice(inputFields, lineItemStates, resolve, reject);
    }).then(
      () =>
        success(
          setInvoice,
          setDraftInvoice,
          rootPath,
          inputFields,
          lineItemStates,
          "$" + inputFields.totalAmount,
          navigate,
          false
        ),
      (errorReason) => failure(errorReason)
    );
  }

  function updateExistingInvoice(inputFields) {
    new Promise(function (resolve, reject) {
      updateInvoice(inputFields, lineItemStates, resolve, reject);
    }).then(
      () =>
        success(
          setInvoice,
          setDraftInvoice,
          rootPath,
          inputFields,
          lineItemStates,
          "$" + inputFields.totalAmount,
          navigate,
          false
        ),
      (errorReason) => failure(errorReason)
    );
  }

  async function validateAllFields() {
    const parentErrorPromises = Object.keys(inputFields).map((field) => handleBlurValidate(field));
    const parentErrorResults = await Promise.all(parentErrorPromises);

    const parentErrors = Object.fromEntries(
      Object.keys(inputFields).map((field, index) => [field, parentErrorResults[index]])
    );

    let parentErrorsEmpty = Object.values(parentErrors).every((value) => value === "");
    let childrenErrorsEmpty = true;

    const childErrorPromises = lineItemStates.map((_, index) => {
      return Object.keys(lineItemStates[index])
        .filter((field) => field != "id")
        .map((field) => childRefs.current[index].childBlurValidate(field));
    });

    const childErrorResults = await Promise.all(childErrorPromises);

    for (let i = 0; i < childErrorResults.length; i++) {
      for (let j = 0; j < childErrorResults[i].length; j++) {
        if (childErrorResults[i][j]["_result"] != "") {
          childrenErrorsEmpty = false;
          break;
        }
      }
    }

    return [parentErrorsEmpty, childrenErrorsEmpty];
  }

  async function handleDraft(e, inputFields) {
    e.preventDefault();

    const [parentErrorsEmpty, childrenErrorsEmpty] = await validateAllFields();

    if (Number(inputFields.totalAmount.replace(/,/g, "")) > Limits.FLOAT_LIMIT) {
      setErrors((prevState) => ({ ...prevState, ["totalAmount"]: Constants.INVOICE_ERROR_TOTAL_LIMIT_EXCEEDED }));
    }

    if (parentErrorsEmpty && childrenErrorsEmpty && inputFields.totalAmount <= Limits.FLOAT_LIMIT) {
      if (!haveDetails) createDraft(inputFields);
      else updateDraft(inputFields);
    }
  }

  async function handleSubmit(e, inputFields) {
    e.preventDefault();

    const [parentErrorsEmpty, childrenErrorsEmpty] = await validateAllFields();

    if (Number(inputFields.totalAmount.replace(/,/g, "")) > Limits.FLOAT_LIMIT) {
      setErrors((prevState) => ({ ...prevState, ["totalAmount"]: Constants.INVOICE_ERROR_TOTAL_LIMIT_EXCEEDED }));
    }

    if (parentErrorsEmpty && childrenErrorsEmpty && Number(inputFields.totalAmount.replace(/,/g, "")) <= Limits.FLOAT_LIMIT) {
      if (!haveDetails) {
        createNewInvoice(inputFields);
      } else {
        updateExistingInvoice(inputFields);
      }
    }
  }

  return (
    <div>
      <Label className="visa-invoice-label-font"> {Constants.INVOICE_CUSTOMER_NAME_LABEL}* </Label>
      <InputContainer>
        <Input
          required
          type="text"
          value={inputFields.name}
          onChange={(e) => handleChange(e, "name")}
          onBlur={() => handleBlurValidate("name")}
          aria-invalid={!!errors["name"]}
        />
      </InputContainer>
      {errors["name"] ? (
        <InputError errorText={errors["name"]} />
      ) : (
        <div className="visa-invoice-standard-spacer"></div>
      )}

      <Label className="visa-invoice-label-font"> {Constants.INVOICE_CUSTOMER_EMAIL_LABEL}* </Label>
      <InputContainer>
        <Input
          required
          type="text"
          value={inputFields.email}
          onChange={(e) => handleChange(e, "email")}
          onBlur={() => handleBlurValidate("email")}
          aria-invalid={!!errors["email"]}
        />
      </InputContainer>
      {errors["email"] ? (
        <InputError errorText={errors["email"]} />
      ) : (
        <div className="visa-invoice-standard-spacer"></div>
      )}

      <Label className="visa-invoice-label-font"> {Constants.INVOICE_NUMBER_LABEL}* </Label>
      <InputContainer>
        <Input
          disabled={haveDetails}
          required
          type="text"
          value={inputFields.invoiceNumber}
          onChange={(e) => handleChange(e, "invoiceNumber")}
          onBlur={() => handleBlurValidate("invoiceNumber")}
          aria-invalid={!!errors["invoiceNumber"]}
        />
      </InputContainer>
      {errors["invoiceNumber"] ? (
        <InputError errorText={errors["invoiceNumber"]} />
      ) : (
        <div className="visa-invoice-standard-spacer"></div>
      )}

      <Label className="visa-invoice-label-font">
        {Constants.INVOICE_DESCRIPTION_LABEL}* &nbsp; <InfoIcon></InfoIcon>
      </Label>
      <InputContainer>
        <TextareaAutosize
          className="v-input visa-invoice-auto-resize-input"
          placeholder={Constants.INVOICE_DESCRIPTION_PLACEHOLDER_TEXT}
          value={inputFields.description}
          onChange={(e) => handleChange(e, "description")}
          onBlur={() => handleBlurValidate("description")}
          aria-invalid={!!errors["description"]}
        />
      </InputContainer>
      <InputMessage className="visa-invoice-description-message">
        <div>{errors["description"] ? <InputError errorText={errors["description"]} /> : null}</div>
        <div className="visa-input-description-char-font">{inputFields.description.length} / 400</div>
      </InputMessage>
      <div className="visa-invoice-med-spacer"></div>

      <Label className="visa-invoice-label-font"> {Constants.INVOICE_DUE_DATE_LABEL}* </Label>
      <InputContainer>
        <Input
          required
          value={inputFields.dueDate}
          onChange={(e) => handleChange(e, "dueDate")}
          onBlur={() => handleBlurValidate("dueDate")}
          aria-invalid={!!errors["dueDate"]}
        />
        {inputFields.dueDate != "" ? (
          <Button
            buttonSize="small"
            iconButton
            colorScheme="tertiary"
            onClick={() => setInputFields({ ...inputFields, ["dueDate"]: "" })}
          >
            <ClearAltTiny className="visa-icons-size" />
          </Button>
        ) : null}
        <Button onClick={() => setIsCalendarOpen(!isCalendarOpen)} buttonSize="small" iconButton colorScheme="tertiary">
          <CalendarTiny className="visa-icons-size" />
        </Button>
      </InputContainer>
      {isCalendarOpen && (
        <DatePicker
          selected={inputFields.dueDate ? parse(inputFields.dueDate, "dd MMM yyyy", new Date()) : ""}
          onChange={(date) => {
            setInputFields({ ...inputFields, dueDate: format(date, "dd MMM yyyy") });
            setIsCalendarOpen(false);
          }}
          dateFormat="dd MMM yyyy"
          inline
        />
      )}
      {errors["dueDate"] ? (
        <InputError errorText={errors["dueDate"]} />
      ) : (
        <div className="visa-invoice-section-spacer"></div>
      )}

      {lineItemStates.map((data, index) => (
        <LineItem
          ref={(element) => (childRefs.current[index] = element)}
          key={data.id}
          itemNumber={index + 1}
          lineItemsLength={lineItemStates.length}
          itemDetails={data}
          updateDetails={(newDetails) => updateDetails(index, newDetails)}
          updateError={(newError) => updateLineItemErrors(index, newError)}
          deleteItem={(deletionIndex) => deleteLineItem(deletionIndex)}
        />
      ))}
      <div ref={endOfLineItemRef} />
      {lineItemStates.length < 30 && (
        <div>
          <div className="visa-banner-add-another-item">
            <Button
              style={{ outline: "none" }}
              id="add-item"
              buttonSize="small"
              iconButton
              colorScheme="tertiary"
              onClick={addComponent}
            >
              <div className="visa-banner-add-another-item-container">
                <AddAltTiny className="visa-icons-size" />
                <p className="visa-banner-add-another-item-font"> {Constants.INVOICE_LIST_ADD_ANOTHER_ITEM} </p>
              </div>
            </Button>
          </div>
          <div className="visa-invoice-section-spacer"></div>
        </div>
      )}

      <div className="visa-invoice-half-grid">
        <div>
          <Label className="visa-invoice-label-font"> {Constants.INVOICE_SHIPPING_LABEL} </Label>
          <InputContainer>
            <p> $ </p>
            <Input
              value={inputFields.shippingAmount}
              onChange={(e) => handleChange(e, "shippingAmount")}
              onBlur={() => {
                handleBlurFormat("shippingAmount", inputFields.shippingAmount);
              }}
              aria-invalid={!!errors["shippingAmount"]}
            />
          </InputContainer>
          {errors["shippingAmount"] ? (
            <InputError errorText={errors["shippingAmount"]} />
          ) : (
            <div className="visa-invoice-section-spacer"></div>
          )}
        </div>
        <div>
          <Label className="visa-invoice-label-font"> {Constants.INVOICE_SHIPPING_TAX_LABEL} </Label>
          <InputContainer>
            <Input
              disabled={
                !inputFields.shippingAmount ||
                !validateField("shippingAmount", inputFields.shippingAmount.replace(/,/g, ""))
              }
              value={inputFields.shippingTax}
              onChange={(e) => handleChange(e, "shippingTax")}
              onBlur={() => {
                handleBlurValidate("shippingTax");
              }}
              aria-invalid={!!errors["shippingTax"]}
            />
            <p> % </p>
          </InputContainer>
          {errors["shippingTax"] ? (
            <div style={{ paddingBottom: 12 }} className="v-input-message">
              {" "}
              <InputError errorText={errors["shippingTax"]} />{" "}
            </div>
          ) : (
            <div className="visa-invoice-section-spacer"></div>
          )}
        </div>
      </div>

      <div className="visa-invoice-total-display">
        <p> {Constants.INVOICE_TOTAL_DUE.toUpperCase()} </p>
        <p>${inputFields.totalAmount}</p>
      </div>
      {errors["totalAmount"] ? <div className="visa-total-error-font"> {errors["totalAmount"]} </div> : null}
      <div className="visa-invoice-bottom-spacer"></div>

      <Button
        className="visa-invoice-button"
        onClick={() => {
          setInputFields((prevState) => {
            const updatedState = { ...prevState, ["invoiceMode"]: "CREATED" };
            handleSubmit(event, updatedState);
            return updatedState;
          });
        }}
      >
        {Constants.INVOICE_INSERT_BUTTON_TEXT}
      </Button>
      {/*
      UNCOMMENT BELOW CODE TO ENABLE DRAFT SAVE BUTTON ON FRONTENT
       <div className="visa-invoice-standard-spacer"></div>
      {((haveDetails && inputFields.invoiceMode === "DRAFT") || !haveDetails) && (
        <Button
          colorScheme="secondary"
          className="visa-invoice-button"
          onClick={() => {
            setInputFields((prevState) => {
              const updatedState = { ...prevState, ["invoiceMode"]: "DRAFT" };
              handleDraft(event, updatedState);
              return updatedState;
            });
          }}
        >
          {haveDetails ? Constants.INVOICE_UPDATE_DRAFT_BUTTON_TEXT : Constants.INVOICE_DRAFT_BUTTON_TEXT}
        </Button>
      )} */}
    </div>
  );
};

export default ListInvoiceComponent;
