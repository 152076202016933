import React, { useEffect, useState } from "react";
import { Button } from "@visa/nova-react";
import * as Constants from "@constants/Constants";
import InsertLinkConfirmation from "@assets/Icon-InsertLink2.svg";
import "@styles/confirmation-styles.css";
import { Link } from "react-router-dom";
import { useInvoice } from "@taskpane/components/SharedCreatedInvoiceContext";

const InsertLinkIconComponent = () => (
  <img src={InsertLinkConfirmation} alt="insert-link" className="visa-insert-link-icon-padding" />
);

export const ConfirmationPage = ({ rootPath }) => {
  const { invoice, setInvoice } = useInvoice();
  const [invoiceSummary] = useState({
    description: invoice ? extractDescription() : "",
    totalAmount: invoice ? invoice.totalAmount : "",
    dueDate: invoice ? invoice.dueDate : "",
  });

  useEffect(() => {
    document.body.style.backgroundColor = Constants.WHITE_BACKGROUND_COLOR_CODE;
    return () => {
      document.body.style.backgroundColor = "";
      setInvoice(() => {});
    };
  }, []);

  function extractDescription() {
    let description = "";
    if (!invoice.confirmationLineItems[0].hasOwnProperty("productName")) {
      description = `(${invoice.confirmationLineItems[0].quantity}) ${invoice.confirmationLineItems[0].description}`;
    } else {
      invoice.confirmationLineItems.forEach((lineItem) => {
        description += `(${lineItem.quantity}) ${lineItem.productName}, `;
      });
      description = description.slice(0, -2);
    }
    return description;
  }

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <InsertLinkIconComponent />
      </div>
      <div className="visa-create-confirmation-padding visa-confirmation-top-text visa-confirmation-top-text-padding">
        {Constants.INVOICE_CONFIRMATION_TOP_TEXT}
      </div>
      <div className="visa-create-confirmation-padding visa-confirmation-details-text visa-confirmation-details-padding">
        {Constants.INVOICE_DESCRIPTION_LABEL}: {invoiceSummary.description}
      </div>
      <div className="visa-create-confirmation-padding visa-confirmation-details-text">
        {Constants.INVOICE_TOTAL_DUE}: {invoiceSummary.totalAmount}
      </div>
      <div className="visa-create-confirmation-padding visa-confirmation-details-text">
        {Constants.INVOICE_DUE_DATE_LABEL}: {invoiceSummary.dueDate}
      </div>
      <div className="visa-create-confirmation-padding visa-confirmation-details-text visa-confirmation-bottom-padding">
        {Constants.INVOICE_CONFIRMATION_BOTTOM_TEXT}
      </div>
      <div className="visa-dashboard-button-padding visa-confirmation-bottom-padding">
        <Link to={rootPath + "dashboard"}>
          <Button
            style={{ width: "100%" }}
            element={<p className="visa-dashboard-button-text">{Constants.GO_TO_DASHBOARD_FROM_CONFIRMATION_TEXT}</p>}
          />
        </Link>
      </div>
    </div>
  );
};
