import React, { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useLocation, useParams } from "react-router";
import DashboardNavBack from "@taskpane/components/DashboardNavBack";
import InvoiceEditButton from "@taskpane/components/InvoiceEditButton";
import { getInvoiceDetails } from "@utilities/invoicingAPI";
import { pasteLinkinEmail } from "@utilities/outlookClient";
import { Button, Divider } from "@visa/nova-react";
import LoadingComponent from "@taskpane/components/LoadingComponent";
import * as Constants from "@constants/Constants";
import "@styles/details-styles.css";
import "@styles/dashboard-styles.css";

const InvoiceDetails = ({recipient, rootPath}) => {
  const { id } = useParams();
  const location = useLocation();
  const status = location.state.status;

  const { isPending, isError, data } = useQuery({
    queryKey: ['details', id],
    queryFn: async () => await getInvoiceDetails(id)
  })
  
  var statusColor: string = Constants.BLACK_UNPAID_COLOR_CODE;

  useEffect(() => {
    document.body.style.backgroundColor = Constants.WHITE_BACKGROUND_COLOR_CODE;
    return () => {
      document.body.style.backgroundColor = "";
    };
  }, []);

  const handlePasteClick = () => {
    pasteLinkinEmail(data.paymentLink, recipient);
  }

  const calculateStatusColor = () => {
    if (status === "PAID") {
      statusColor = Constants.GREEN_PAID_COLOR_CODE;
    } else if (status === "OVERDUE") {
      statusColor = Constants.RED_OVERDUE_COLOR_CODE;
    } else if (status === "DRAFT") {
      statusColor = Constants.BLUE_DRAFT_COLOR_CODE;
    }
  };

  calculateStatusColor();

  if (isPending) {
    return <LoadingComponent text="Loading..."></LoadingComponent>;
  }

  if (isError) {
    return <p className="visa-table-no-data-font"> {Constants.DETAILS_DATA_ERROR} </p>
  }

  return (
    <div>
      <div className="visa-invoice-details-header-padding visa-invoice-details-spacing">
        <DashboardNavBack rootPath={rootPath} />
        {status != "PAID" && <InvoiceEditButton invoiceDetails={data} lineItems={data.lineItems.length} rootPath={rootPath}/>}
      </div>
      <div style={{ backgroundColor: "#0000001A", height: "2.5px" }}></div>
      <div className="visa-invoice-details-after-break-padding visa-invoice-details-spacing visa-invoice-details-big-font">
        <p> Invoice Number </p>
        <p> {id} </p>
      </div>
      <div className="visa-invoice-details-top-lines-padding visa-invoice-details-spacing visa-invoice-details-big-font">
        <p> Status </p>
        <p style={{ color: statusColor, fontWeight: 700 }}> {status} </p>
      </div>
      <div className="visa-invoice-details-after-top-lines-padding visa-invoice-details-spacing visa-invoice-details-normal-font">
        <p> Due date </p>
        <p> {data.dueDate} </p>
      </div>
      <div className="visa-invoice-details-normal-lines-padding visa-invoice-details-spacing visa-invoice-details-normal-font">
        <p> Date sent </p>
        <p> {data.invoiceCreationDate} </p>
      </div>
      <div className="visa-invoice-details-normal-lines-padding visa-invoice-details-spacing visa-invoice-details-normal-font">
        <p> Date paid </p>
        <p> {data.datePaid} </p>
      </div>
      <div className="visa-invoice-details-after-dates-lines-padding visa-invoice-details-spacing visa-invoice-details-normal-font">
        <p> Customer name </p>
        <p> {data.customerName} </p>
      </div>
      <div className="visa-invoice-details-normal-lines-padding visa-invoice-details-spacing visa-invoice-details-normal-font">
        <p> Customer email </p>
        <p> {data.customerEmail} </p>
      </div>
      <div className="visa-invoice-details-after-names-lines-padding visa-invoice-details-normal-font">
        <p style={{ paddingBottom: "2px" }}> Description </p>
        <Divider></Divider>
        <p style={{ paddingTop: "10px", paddingBottom: "15px" }}> {data.description} </p>
        <Divider></Divider>
      </div>
      <div className="visa-invoice-details-after-break-padding visa-invoice-details-spacing visa-invoice-details-big-font">
        <p> Amount </p>
        <p style={{ fontWeight: 700 }}> ${data.totalAmount} </p>
      </div>
      {status != "PAID" && status != "DRAFT" && (
        <div className="visa-button-padding">
          <Button className="visa-invoice-details-button" onClick={handlePasteClick}>{Constants.INVOICE_INSERT_BUTTON_TEXT}</Button>
        </div>
      )}
    </div>
  );
};

export default InvoiceDetails;
