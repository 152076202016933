import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from "react";
import * as Constants from "@constants/Constants";
import * as Limits from "@constants/InputLimits";
import InputError from "@taskpane/components/InputError";
import { validateField } from "@utilities/invoiceInputValidation";
import { formatAmount, formatPercents, calculateTotal } from "@utilities/textFormat";
const writtenNumber = require("written-number") as any;
import "@styles/dashboard-styles.css";
import {
  Input,
  InputContainer,
  Label,
  Radio,
  Toggle,
  ToggleContainer,
  Button,
  Dialog,
  DialogCloseButton,
  DialogContent,
  DialogHeader,
  DialogIcon,
  Typography,
} from "@visa/nova-react";
import { DeleteTiny } from "@visa/nova-icons/react/visa";

interface LineItemProps {
  itemNumber: any;
  lineItemsLength: any;
  itemDetails: any;
  updateDetails: (newDetails: any) => void;
  updateError: (newError: any) => void;
  deleteItem: (deletionIndex: any) => void;
}

const LineItem = forwardRef<HTMLDivElement, LineItemProps>(
  ({ itemNumber, lineItemsLength, itemDetails, updateDetails, updateError, deleteItem }, ref) => {
    const haveItem = Object.keys(itemDetails).length > 0;
    const [lineItem, setLineItem] = useState({
      productName: haveItem ? itemDetails["productName"] : "",
      amount: haveItem ? itemDetails["amount"] : "",
      quantity: haveItem ? itemDetails["quantity"] : "",
      discountAmount: haveItem ? itemDetails["discountAmount"] : "",
      discountPercent: haveItem ? itemDetails["discountPercent"] : "",
      taxAmount: haveItem ? itemDetails["taxAmount"] : "",
      taxRate: haveItem ? itemDetails["taxRate"] : "",
      totalAmount: haveItem ? itemDetails["totalAmount"] : "0.00",
    });
    const [amountQuantityTotal, setAmountQuantityTotal] = useState(
      haveItem ? calculateTotal(lineItem.amount, lineItem.quantity) : ""
    );
    const [amountQuantityTotalDiscount, setAmountQuantityTotalDiscount] = useState(
      haveItem ? calculateTotal(lineItem.amount, lineItem.quantity, lineItem.discountAmount) : ""
    );
    const [discountType, setDiscountType] = useState("$");
    const [discountInput, setDiscountInput] = useState(lineItem.discountAmount);
    const [errors, setErrors] = useState({});
    const inputToLabelMap = {
      productName: Constants.INVOICE_ITEM_NAME_LABEL,
      amount: Constants.INVOICE_AMOUNT_LABEL,
      quantity: Constants.INVOICE_QUANTITY_LABEL,
    };
    var optionalFields = new Set(["discountAmount", "discountPercent", "taxRate", "taxAmount"]);
    const innerRef = useRef<HTMLDivElement>(null);
    const warningDialogRef = useRef<HTMLDialogElement>(null);

    useEffect(() => {
      setLineItem((prevState) => {
        return {
          ...prevState,
          ["totalAmount"]: calculateTotal(
            lineItem.amount,
            lineItem.quantity,
            lineItem.discountAmount,
            lineItem.taxRate
          ),
        };
      });
      setAmountQuantityTotal(calculateTotal(lineItem.amount, lineItem.quantity));
      setAmountQuantityTotalDiscount(calculateTotal(lineItem.amount, lineItem.quantity, lineItem.discountAmount));
    }, [lineItem.amount, lineItem.quantity, lineItem.discountAmount, lineItem.taxRate, lineItem.taxAmount]);

    useEffect(() => {
      if (itemDetails != lineItem) {
        updateDetails(lineItem);
      }
    }, [lineItem]);

    useEffect(() => {
      updateError(errors);
    }, [errors]);

    function handleInputChange(e, fieldName: string) {
      const value = e.target.value;
      setLineItem((prevState) => {
        return { ...prevState, [fieldName]: value };
      });
    }

    function handleDiscountChange(e) {
      const value = e.target.value;
      setDiscountInput(value);
      if (discountType === "$") {
        setLineItem((prevState) => {
          return { ...prevState, ["discountAmount"]: value };
        });
      } else {
        setLineItem((prevState) => {
          return { ...prevState, ["discountPercent"]: value };
        });
      }
    }

    function handleToggleChange(type) {
      setDiscountType(type);
      setDiscountInput(type === "$" ? lineItem.discountAmount : lineItem.discountPercent);
    }

    function handleDeleteClick() {
      deleteItem(itemNumber - 1);
    }

    function handleBlurCalculate(fieldName: string) {
      if (fieldName === "amount" || fieldName === "quantity") {
        if (lineItem[fieldName] != "") {
          setLineItem((prevState) => {
            return {
              ...prevState,
              ["discountPercent"]: lineItem.discountAmount
                ? formatPercents(
                    (parseFloat(lineItem.discountAmount) / parseFloat(amountQuantityTotal.replace(/,/g, ""))) * 100
                  )
                : "",
              ["taxAmount"]: lineItem.taxRate
                ? (
                    (parseFloat(lineItem.taxRate) / 100) *
                    parseFloat(amountQuantityTotalDiscount.replace(/,/g, ""))
                  ).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                : "",
            };
          });
        }
      }
      if (fieldName === "discountAmount") {
        if (
          lineItem[fieldName] != "" &&
          validateField(fieldName, lineItem.discountAmount) &&
          Number(lineItem.discountAmount) <= parseFloat(amountQuantityTotal.replace(/,/g, ""))
        ) {
          setLineItem((prevState) => {
            return {
              ...prevState,
              ["discountPercent"]: formatPercents(
                (parseFloat(lineItem.discountAmount) / parseFloat(amountQuantityTotal.replace(/,/g, ""))) * 100
              ),
            };
          });
          setErrors((prevState) => {
            return { ...prevState, ["discountPercent"]: "" };
          });
        } else {
          setLineItem((prevState) => {
            return { ...prevState, ["discountPercent"]: "" };
          });
        }
      } else if (fieldName === "discountPercent") {
        if (
          lineItem[fieldName] != "" &&
          validateField(fieldName, lineItem.discountPercent) &&
          Number(lineItem.discountPercent) <= 100
        ) {
          setLineItem((prevState) => {
            return {
              ...prevState,
              ["discountAmount"]: (
                (parseFloat(lineItem.discountPercent) / 100) *
                parseFloat(amountQuantityTotal.replace(/,/g, ""))
              ).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
            };
          });
          setErrors((prevState) => {
            return { ...prevState, ["discountAmount"]: "" };
          });
        } else {
          setLineItem((prevState) => {
            return { ...prevState, ["discountAmount"]: "" };
          });
        }
      } else if (fieldName === "taxRate") {
        if (lineItem[fieldName] != "") {
          setLineItem((prevState) => {
            return {
              ...prevState,
              ["taxAmount"]: (
                (parseFloat(lineItem.taxRate) / 100) *
                parseFloat(amountQuantityTotalDiscount.replace(/,/g, ""))
              ).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
            };
          });
        } else {
          setLineItem((prevState) => {
            return { ...prevState, ["taxAmount"]: "" };
          });
        }
      }
    }

    useImperativeHandle(ref, () => ({
      ...innerRef.current,
      childBlurValidate: (fieldName: string) => {
        return new Promise((resolve) => {
          resolve(handleBlurValidate(fieldName));
        });
      },
    }));

    function handleBlurValidate(fieldName: string) {
      return new Promise((resolve) => {
        let errorMessage = "";

        if (!optionalFields.has(fieldName) && !lineItem[fieldName]) {
          errorMessage = inputToLabelMap[fieldName] + Constants.INVOICE_ERROR_EMPTY_TEXT;
        } else if (fieldName === "amount") {
          if (!validateField(fieldName, lineItem[fieldName].replace(/,/g, ""))) {
            errorMessage = inputToLabelMap[fieldName] + Constants.INVOICE_ERROR_INVALID_TEXT;
          } else if (Number(lineItem[fieldName].replace(/,/g, "")) > Limits.FLOAT_LIMIT) {
            errorMessage = Constants.INVOICE_ERROR_AMOUNT_LIMIT_EXCEEDED;
          }
        } else if (fieldName === "quantity") {
          if (!validateField(fieldName, lineItem[fieldName])) {
            errorMessage = inputToLabelMap[fieldName] + Constants.INVOICE_ERROR_INVALID_TEXT;
          } else if (Number(lineItem[fieldName]) > Limits.QUANTITY_LIMIT) {
            errorMessage = Constants.INVOICE_ERROR_QUANTITY_LIMIT_EXCEEDED;
          }
        } else if (fieldName === "taxRate" || fieldName === "discountPercent") {
          if (!validateField(fieldName, lineItem[fieldName])) {
            errorMessage = Constants.INVOICE_ERROR_DECIMALS_TEXT;
          } else if (Number(lineItem[fieldName]) > Limits.PERCENT_LIMIT) {
            errorMessage = Constants.INVOICE_ERROR_PERCENT_TEXT;
          }
        } else if (fieldName === "discountAmount") {
          if (!validateField(fieldName, lineItem[fieldName])) {
            errorMessage = Constants.INVOICE_ERROR_DECIMALS_TEXT_DISCOUNT;
          } else if (Number(lineItem[fieldName]) > Number(amountQuantityTotal.replace(/,/g, ""))) {
            errorMessage = Constants.INVOICE_ERROR_GREATER_THAN_TOTAL + `$${amountQuantityTotal}`;
          }
        } else if (fieldName === "productName" && lineItem[fieldName].length > Limits.PRODUCT_NAME_LENGTH_LIMIT) {
          errorMessage = Constants.INVOICE_ERROR_LINE_ITEM_NAME_LENGTH_EXCEEDED;
        }

        setErrors((prevState) => {
          return { ...prevState, [fieldName]: errorMessage };
        });
        resolve(errorMessage);
      });
    }

    function handleBlurFormat(fieldName: string, input: string) {
      handleBlurValidate(fieldName).then((errorMessage) => {
        if (errorMessage === "") {
          setLineItem({ ...lineItem, [fieldName]: formatAmount(input) });
        }
      });
    }

    return (
      <div>
        <div className="visa-banner-item">
          <div style={{ display: "flex" }}>
            {lineItemsLength > 1 && (
              <>
                <Button
                  style={{ outline: "none", marginLeft: 20, paddingBottom: 10 }}
                  id="delete-item"
                  buttonSize="small"
                  iconButton
                  colorScheme="tertiary"
                  onClick={() => warningDialogRef.current?.showModal()}
                >
                  <DeleteTiny className="visa-icons-size" style={{ color: "#be2d2d" }} />
                </Button>
                <Dialog
                  style={{ width: 390 }}
                  aria-describedby="warning-dialog-description"
                  aria-labelledby="warning-dialog-title"
                  ref={warningDialogRef}
                  id="warning-dialog"
                  messageType="warning"
                >
                  <DialogContent>
                    <DialogHeader id="warning-dialog-title" className="visa-item-delete-title">
                      <DialogIcon />
                      Delete
                    </DialogHeader>
                    <Typography id="warning-dialog-description" className="visa-item-delete-body">
                      Are you sure you want to delete item {writtenNumber(itemNumber)}?
                    </Typography>
                    <div className="v-flex v-flex-wrap v-gap-8 v-pt-16 v-align-items-center">
                      <Button onClick={handleDeleteClick} className="visa-item-delete-confirmation-buttons">
                        Delete
                      </Button>
                      <Button
                        colorScheme="secondary"
                        className="visa-item-delete-confirmation-buttons"
                        onClick={() => warningDialogRef.current?.close()}
                      >
                        Cancel
                      </Button>
                    </div>
                  </DialogContent>
                  <DialogCloseButton onClick={() => warningDialogRef.current?.close()} />
                </Dialog>
              </>
            )}
            <p className={`${lineItemsLength > 1 ? "visa-banner-item-font-left" : "visa-banner-item-font-left-alt"}`}>
              {" "}
              Item {writtenNumber(itemNumber)}{" "}
            </p>
          </div>
          <p className="visa-banner-item-font">${lineItem.totalAmount}</p>
        </div>

        <Label className="visa-invoice-label-font visa-invoice-line-item-first-padding">
          {Constants.INVOICE_ITEM_NAME_LABEL}*
        </Label>
        <InputContainer>
          <Input
            id={`new-component-input-${itemNumber}`}
            required
            value={lineItem.productName}
            onChange={(e) => handleInputChange(e, "productName")}
            onBlur={() => handleBlurValidate("productName")}
            aria-invalid={!!errors["productName"]}
          />
        </InputContainer>
        {errors["productName"] ? (
          <InputError errorText={errors["productName"]} />
        ) : (
          <div className="visa-invoice-standard-spacer"></div>
        )}

        <div className="visa-invoice-half-grid">
          <div>
            <Label className="visa-invoice-label-font"> {Constants.INVOICE_AMOUNT_LABEL}* </Label>
            <InputContainer>
              <p> $ </p>
              <Input
                required
                value={lineItem.amount}
                onChange={(e) => handleInputChange(e, "amount")}
                onBlur={() => {
                  handleBlurFormat("amount", lineItem.amount);
                  handleBlurCalculate("amount");
                }}
                aria-invalid={!!errors["amount"]}
              />
            </InputContainer>
            {errors["amount"] ? (
              <InputError errorText={errors["amount"]} />
            ) : (
              <div className="visa-invoice-standard-spacer"></div>
            )}
          </div>
          <div>
            <Label className="visa-invoice-label-font"> {Constants.INVOICE_QUANTITY_LABEL}* </Label>
            <InputContainer>
              <Input
                required
                value={lineItem.quantity}
                onChange={(e) => handleInputChange(e, "quantity")}
                onBlur={() => {
                  handleBlurValidate("quantity");
                  handleBlurCalculate("quantity");
                }}
                aria-invalid={!!errors["quantity"]}
              />
            </InputContainer>
            {errors["quantity"] ? (
              <InputError errorText={errors["quantity"]} />
            ) : (
              <div className="visa-invoice-standard-spacer"></div>
            )}
          </div>
        </div>

        <div className="visa-invoice-third-grid-list">
          <div style={{ marginTop: "20px", display: "flex", height: "45px" }}>
            <ToggleContainer>
              <Toggle htmlFor={`toggle-dollar-${itemNumber}`} style={{ height: "45px" }}>
                <Radio
                  id={`toggle-dollar-${itemNumber}`}
                  name={`toggle-discount-${itemNumber}`}
                  onChange={() => handleToggleChange("$")}
                  defaultChecked
                />
                $
              </Toggle>
              <Toggle htmlFor={`toggle-percent-${itemNumber}`} style={{ height: "45px" }}>
                <Radio
                  id={`toggle-percent-${itemNumber}`}
                  name={`toggle-discount-${itemNumber}`}
                  onChange={() => handleToggleChange("%")}
                />
                %
              </Toggle>
            </ToggleContainer>
          </div>
          <div>
            <Label className="visa-invoice-label-font"> {Constants.INVOICE_DISCOUNT_LABEL} </Label>
            <InputContainer>
              {discountType === "$" && <p> $ </p>}
              <Input
                disabled={
                  !lineItem.amount ||
                  !validateField("amount", lineItem.amount) ||
                  !lineItem.quantity ||
                  !validateField("quantity", lineItem.quantity)
                }
                value={discountInput}
                onChange={handleDiscountChange}
                onBlur={() => {
                  discountType === "$" ? handleBlurValidate("discountAmount") : handleBlurValidate("discountPercent");
                  discountType === "$" ? handleBlurCalculate("discountAmount") : handleBlurCalculate("discountPercent");
                }}
                aria-invalid={discountType === "$" ? !!errors["discountAmount"] : !!errors["discountPercent"]}
              />
              {discountType === "%" && <p> % </p>}
            </InputContainer>
            {discountType === "$" ? (
              errors["discountAmount"] ? (
                <div style={{ paddingBottom: 12 }} className="v-input-message">
                  <InputError errorText={errors["discountAmount"]} />
                </div>
              ) : null
            ) : errors["discountPercent"] ? (
              <div style={{ paddingBottom: 12 }} className="v-input-message">
                <InputError errorText={errors["discountPercent"]} />
              </div>
            ) : null}
          </div>
          <div>
            <Label className="visa-invoice-label-font"> {Constants.INVOICE_TAX_LABEL} </Label>
            <InputContainer>
              <Input
                disabled={
                  !lineItem.amount ||
                  !validateField("amount", lineItem.amount) ||
                  !lineItem.quantity ||
                  !validateField("quantity", lineItem.quantity)
                }
                value={lineItem.taxRate}
                onChange={(e) => handleInputChange(e, "taxRate")}
                onBlur={() => {
                  handleBlurValidate("taxRate");
                  handleBlurCalculate("taxRate");
                }}
                aria-invalid={!!errors["taxRate"]}
              />
              <p> % </p>
            </InputContainer>
            {errors["taxRate"] ? (
              <div style={{ paddingBottom: 12 }} className="v-input-message">
                <InputError errorText={errors["taxRate"]} />
              </div>
            ) : (
              <div className="visa-invoice-section-spacer"></div>
            )}
          </div>
        </div>
      </div>
    );
  }
);

export default LineItem;
