// PrivateRoute.ts
import { Navigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import * as Constants from "@constants/Constants";
import WelcomePage from "@taskpane/pages/Welcome/WelcomePage";


// This is a custom private route component which will redirect to different pages based on the user's loggedin status.
// If the user is loggedin then it will redirect to dashboard. If not then it will redirect to welcome page.
// Also, if it is the first time the user is opening the add-in then it will redirect to welcome page.
export const PrivateRoute = ({rootPath, isAuthenticated}) => {
    const isFirstOpen = localStorage.getItem(Constants.FIRST_OPEN_LOCAL_STORAGE)
    if (isFirstOpen === 'true' || isFirstOpen === null) {
        return <WelcomePage rootPath={rootPath} />;
    } else {
        if (!isAuthenticated) {
            return <Navigate to={rootPath + 'environment'} />;
        } else {
            return <Navigate to={rootPath + 'dashboard'} />;
        }
    }
}

