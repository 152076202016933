import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { getListOfInvoices } from "@utilities/invoicingAPI";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  TableSortLabel,
} from "@mui/material";
import "@styles/dashboard-styles.css";
import * as Constants from "@constants/Constants";
import LoadingComponent from "@taskpane/components/LoadingComponent";
import { Link } from "react-router-dom";
import VLink from "@visa/nova-react/link";

const InvoiceTable = ({ customerId, rootPath }) => {
  const { isPending, isError, data } = useQuery({
    queryKey: ["list", customerId],
    queryFn: async () => await getListOfInvoices("0", customerId),
  });

  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState("status");
  const [invoices, setInvoices] = useState([]);

  useEffect(() => {
    if (!isPending && !isError && data) {
      setInvoices(data);
    }
  }, [data, isPending, isError]);

  const calculateStatusColor = (status: string) => {
    if (status === "PAID") {
      return Constants.GREEN_PAID_COLOR_CODE;
    } else if (status === "OVERDUE") {
      return Constants.RED_OVERDUE_COLOR_CODE;
    } else if (status === "DRAFT") { 
      return Constants.BLUE_DRAFT_COLOR_CODE;
    } else {
      return Constants.BLACK_UNPAID_COLOR_CODE;
    }
  };

  const handleSort = (columnName) => () => {
    const prevOrderBy = orderBy;
    const isAscending = prevOrderBy === columnName && order === "asc";
    setOrder(isAscending ? "desc" : "asc");
    setOrderBy(columnName);

    const compare = (val1, val2) => {
      if (columnName === "dueDate") return new Date(val1[columnName]).getTime() - new Date(val2[columnName]).getTime();
      if (columnName === "status") {
        const statusOrder = ["OVERDUE", "UNPAID", "PAID", "DRAFT"];
        return statusOrder.indexOf(val1[columnName]) - statusOrder.indexOf(val2[columnName]);
      } else return val1[columnName].localeCompare(val2[columnName]);
    };

    const sortedInvoices = [...data].sort((val1, val2) => {
      const comparison = compare(val1, val2);
      if (order === "asc") {
        if (prevOrderBy != columnName) return comparison;
        else return -comparison;
      } else return comparison;
    });

    setInvoices(sortedInvoices);
  };

  if (isPending) {
    return <LoadingComponent text="Getting invoices..."></LoadingComponent>;
  }

  if (isError) {
    return <p className="visa-table-no-data-font"> {Constants.DASHBOARD_DATA_ERROR} </p>;
  }

  return (
    <React.Fragment>
      <Table>
        <TableHead>
          <TableRow className="visa-sticky-header" style={{ height: "52px" }}>
            <TableCell component="th">
              <TableSortLabel
                active={orderBy === "invoiceNo"}
                direction={order}
                onClick={handleSort("invoiceNo")}
                sx={{
                  "& .MuiTableSortLabel-icon": {
                    color: Constants.BLUE_PRIMARY_COLOR,
                  },
                }}
              >
                <p className="visa-table-header-font"> Invoice number </p>
              </TableSortLabel>
            </TableCell>
            <TableCell component="th">
              <p className="visa-table-header-font"> Amount </p>
            </TableCell>
            <TableCell component="th">
              <TableSortLabel
                active={orderBy === "dueDate"}
                direction={order}
                onClick={handleSort("dueDate")}
                sx={{
                  "& .MuiTableSortLabel-icon": {
                    color: Constants.BLUE_PRIMARY_COLOR,
                  },
                }}
              >
                <p className="visa-table-header-font"> Due date </p>
              </TableSortLabel>
            </TableCell>
            <TableCell component="th">
              <TableSortLabel
                active={orderBy === "status"}
                direction={order}
                onClick={handleSort("status")}
                sx={{
                  "& .MuiTableSortLabel-icon": {
                    color: Constants.BLUE_PRIMARY_COLOR,
                  },
                }}
              >
                <p className="visa-table-header-font"> Status </p>
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invoices.map((row, index) => {
            return (
              <TableRow key={row.invoiceNo} style={{ height: "42px" }}>
                <TableCell sx={{ wordBreak: "break-word", overflowWrap: "break-word" }}>
                  <VLink
                    element={
                      <Link to={rootPath + `details/${row.invoiceNo}`} state={{ status: row.status }}>
                        <p className="visa-invoice-no-font"> {row.invoiceNo} </p>
                      </Link>
                    }
                    noUnderline
                  />
                </TableCell>
                <TableCell>
                  <p className="visa-amount-font"> {row.amount} </p>
                </TableCell>
                <TableCell sx={{ whiteSpace: "nowrap" }}>
                  <p className="visa-dates-font"> {row.dueDate} </p>
                </TableCell>
                <TableCell>
                  <p className="visa-status-font" style={{ color: calculateStatusColor(row.status) }}>
                    {row.status}
                  </p>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {data.length == 0 && <p className="visa-table-no-data-font"> {Constants.DASBOARD_TABLE_NO_DATA} </p>}
    </React.Fragment>
  );
};

export default InvoiceTable;
