import * as React from "react";
import { DefaultButton } from "@fluentui/react";
import Progress from "@taskpane/components/Progress";

/* global require */

export interface AppProps {
  title: string;
  isOfficeInitialized: boolean;
}

export default class App extends React.Component<AppProps> {
  constructor(props, context) {
    super(props, context);
  }


  click = async () => {
    /**
     * Insert your Outlook code here
     */
    window.location.href = "https://businesscentertest.visaacceptance.com/ebc2/";
  };

  render() {
    const { title, isOfficeInitialized } = this.props;

    if (!isOfficeInitialized) {
      return (
        <Progress
          title={title}
          logo={require("./../../../assets/Logo-VisaInvoice.png")}
          message="Please sideload your addin to see app body."
        />
      );
    }

    return (
      <div>
        
      </div>
    );
  }
}
