import React from "react";
import OptionsComponent from "@taskpane/components/OptionsComponent";
import "@styles/dashboard-styles.css";

const More = ({ recipient, rootPath, logOutHandler }) => {
  return (
    <div>
      <div className="visa-dashboard-spacer" />
      <div className="visa-banner-dashboard">
        <p className="visa-banner-dashboard-font"> Invoices for {recipient} </p>
      </div>
      <div className="visa-dashboard-spacer" />
      <div>
        <OptionsComponent rootPath={rootPath}  logOutHandler ={logOutHandler}/>
      </div>
    </div>
  );
};

export default More;