import React from "react";
import { InputMessage } from "@visa/nova-react";
import { ErrorTiny } from '@visa/nova-icons/react/visa';
import "@styles/dashboard-styles.css";

interface InputErrorProps {
    errorText: string;
}

const InputError: React.FC<InputErrorProps> = ({errorText}) => {

     return (
        <InputMessage className="visa-invoice-error-font">
          <ErrorTiny className="visa-icons-size"/>
          {errorText}
        </InputMessage>
     )
}

export default InputError;  