import React, { createContext, useContext, useState } from "react";

interface SharedDetailsContextType {
    details: object;
    setDetails: React.Dispatch<React.SetStateAction<object>>;
}

export const SharedDetailsContext = createContext<SharedDetailsContextType | undefined>(undefined);

export const SharedDetailsProvider: React.FC = ({ children }) => {
    const [details, setDetails] = useState( { listInvoice: {} } );

    return (
        <SharedDetailsContext.Provider value={{ details, setDetails }}>
            {children}
        </SharedDetailsContext.Provider>
    )
}

export const useDetails = () => {
    const context = useContext(SharedDetailsContext);
    return context;
}

