import Surface from '@visa/nova-react/surface';
import React, { useEffect } from 'react';
import { Button, Label } from '@visa/nova-react';
import '@taskpane/taskpane.css';
import "@styles/dashboard-styles.css";
import * as Constants from '@constants/Constants';
import {NavLink, useNavigate} from "react-router-dom"


const welcomePageComponent = ({rootPath}) => {
  const navigate = useNavigate();
  const handleClick = () => {
    localStorage.setItem('environment', 'Sandbox')
    window.dispatchEvent(new Event("storage"));
    window.dispatchEvent(new Event("first_ack"));
    navigate(rootPath + "environment")
  };

  useEffect(() => {
    document.body.style.backgroundColor = Constants.WHITE_BACKGROUND_COLOR_CODE;
    return () => {
      document.body.style.backgroundColor = "";
    };
  }, []);

  return (
    <div>
      <Surface>
        <Surface>
          <img src={rootPath+"assets/Logo-VisaInvoice.png"} alt="Visa Invoice Logo" className='visa_logo_branding'/>
        </Surface>
      </Surface>
      <Surface className='visa-left-right-padding'>
        <p className='visa-welcome-title'>{Constants.VISA_WELCOME_PAGE_TITLE}</p>
        <br/>
      </Surface>
      <Surface className='visa-left-right-padding'>
        <p className='visa-welcome-heading'>{Constants.VISA_WELCOME_PAGE_HEADING}</p>
        <br/><br/>
        <p className='visa-welcome-subtext'>{Constants.VISA_WELCOME_PAGE_LABEL}</p>
      </Surface>
      <Surface className='visa-left-right-padding'>
        <br/>
        <Button onClick={handleClick} buttonSize='large' className='get_started'>{Constants.VISA_WELCOME_PAGE_GET_STARTED_BUTTON_LABEL}</Button>
        <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
      </Surface>
    </div>
  );

}

export default welcomePageComponent;