import * as Constants from "@constants/Constants";
import { EnvironmentTypes } from '@constants/environmentEnum';

export const pasteLinkinEmail = async (paymentLink : string, recipient: string) => {
    var emailBodyLink = '<br><br><a href =' + paymentLink + '>'
    var selectedEnv: string = localStorage.getItem(Constants.ENVIRONMENT_LOCAL_STORAGE);
    if(selectedEnv === EnvironmentTypes.Production){
        emailBodyLink = emailBodyLink + Constants.PAY_HREF_PRODUCTION
    } else {
        emailBodyLink = emailBodyLink + Constants.PAY_HREF_SANDBOX
    }

    var emailBodyText = 'Hi ' + recipient + ","+ Constants.PAY_INVOICE_EMAIL_TEXT + emailBodyLink
    if(selectedEnv === EnvironmentTypes.Sandbox){
        emailBodyText = emailBodyText + Constants.PAY_INSTRUCTION_SANDBOX
    }

    // Identify the body type of the mail item.
    Office.context.mailbox.item.body.getTypeAsync((asyncResult) => {
         if (asyncResult.status === Office.AsyncResultStatus.Failed) {
              return;
          }
          // Insert data of the appropriate type into the body.
          if (asyncResult.value === Office.CoercionType.Html) {
              // Insert HTML into the body.
              Office.context.mailbox.item.body.setSelectedDataAsync(
                  emailBodyText,
                  { coercionType: Office.CoercionType.Html, asyncContext: { optionalVariable1: 1, optionalVariable2: 2 } },
                  (asyncResult) => {
                      if (asyncResult.status === Office.AsyncResultStatus.Failed) {
                          return;
                      }
                      /*
                        Run additional operations appropriate to your scenario and
                        use the optionalVariable1 and optionalVariable2 values as needed.
                      */
              });
          }
      });

};

function asyncFindCustomerEmail(){
    return new Promise((resolve,reject)=>{
        Office.context.mailbox.item.to.getAsync(function(asyncResult) {
            if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
              const msgTo = asyncResult.value;
              // return first recipient email.
              resolve(msgTo[0].emailAddress+"")
            } else {
              reject(asyncResult.error);
            }
          });
    });
}

export const getCustomerEmail = async () => {
    let customerEmail = await asyncFindCustomerEmail()
    return customerEmail
};

export const getIdentityToken = () => {
    let exchangeServerRequest = new Promise(function(resolve, reject){
      Office.context.mailbox.getUserIdentityTokenAsync(function (result) {
      if (result.status !== Office.AsyncResultStatus.Succeeded) {
        reject(Constants.IDENTITY_TOKEN_FETCH_ERROR)
      } else {
        let encodedIdentityToken = result.value;
        resolve(encodedIdentityToken)
      }
      });
    })
    return exchangeServerRequest;
}