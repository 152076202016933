import React from "react";
import * as Constants from "@constants/Constants";
import "@styles/details-styles.css";
import "@styles/dashboard-styles.css";
import { ArrowBackTiny } from "@visa/nova-icons/react/visa";
import { Link } from "react-router-dom";

const DashboardNavBack = ({rootPath}) => {
  return (
    <Link to={rootPath + "dashboard"} style={{ textDecoration: 'none' }}>
      <div className="v-flex v-gap-5 v-align-items-center">
        <ArrowBackTiny className="visa-icons-size" />
        <p className="visa-invoice-back-text"> {Constants.BACK_TO_DASHBOARD_TEXT} </p>
      </div>
    </Link>
  );
};

export default DashboardNavBack;
