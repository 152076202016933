import React from "react";
import SimpleInvoiceComponent from "@taskpane/components/SimpleInvoiceComponent";
import "@styles/dashboard-styles.css";

const SimpleInvoice = ({rootPath, customerId}) => {
  return (
    <div>
      <div className="visa-dashboard-spacer" />
      <div className="visa-banner-dashboard">
        <p className="visa-banner-dashboard-font"> All fields are required </p>
      </div>
      <div className="visa-dashboard-spacer" />
      <div className="visa-invoice-styling">
        <SimpleInvoiceComponent customerId={customerId} rootPath={rootPath}/>
      </div>
    </div>
  );
};

export default SimpleInvoice;
