import React, { useState, useEffect, useRef } from "react";
import { Button, Input, InputContainer, InputMessage, Label, Textarea } from "@visa/nova-react";
import TextareaAutosize from "react-textarea-autosize";
import "@styles/dashboard-styles.css";
import * as Constants from "@constants/Constants";
import * as Limits from "@constants/InputLimits";
import InfoIcon from "@taskpane/components/InfoIcon";
import InputError from "@taskpane/components/InputError";
import { CalendarTiny } from "@visa/nova-icons/react/visa";
import { ClearAltTiny } from "@visa/nova-icons/react/generic";
import { validateField } from "@utilities/invoiceInputValidation";
import { formatAmount, calculateTotal } from "@utilities/textFormat";
import { useNavigate } from "react-router-dom";
import { createInvoice } from "@utilities/invoicingAPI";
import { useInvoice } from "@taskpane/components/SharedCreatedInvoiceContext";
import { useDraftInvoice } from "@taskpane/components/SharedInvoiceStateContext";
import DatePicker from "react-datepicker";
import { format, parse, isValid } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import { utcToZonedTime } from "date-fns-tz";
import { success, failure } from "@utilities/invoiceSubmission";

const SimpleInvoiceComponent = ({ rootPath, customerId }) => {
  const { setInvoice } = useInvoice();
  const { draftInvoice, setDraftInvoice } = useDraftInvoice();
  const haveDraft = Object.keys(draftInvoice["simpleInvoice"]).length > 0;
  const navigate = useNavigate();
  const date = new Date();
  const [inputFields, setInputFields] = useState({
    name: haveDraft ? draftInvoice["simpleInvoice"]["name"] : "",
    email: haveDraft ? draftInvoice["simpleInvoice"]["email"] : customerId,
    invoiceNumber:
      Constants.PREFIX_UNIQUE_INVOICE_NUMBER +
      date.getFullYear() +
      date.getMonth() +
      date.getDate() +
      date.getHours() +
      date.getMinutes() +
      date.getSeconds(),
    description: haveDraft ? draftInvoice["simpleInvoice"]["description"] : "",
    amount: haveDraft ? draftInvoice["simpleInvoice"]["amount"] : "",
    quantity: "1",
    dueDate: format(date, "dd MMM yyyy"),
    invoiceMode: "DRAFT",
  });
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const inputToLabelMap = {
    name: Constants.INVOICE_CUSTOMER_NAME_LABEL,
    email: Constants.INVOICE_CUSTOMER_EMAIL_LABEL,
    invoiceNumber: Constants.INVOICE_NUMBER_LABEL,
    description: Constants.INVOICE_DESCRIPTION_LABEL,
    amount: Constants.INVOICE_AMOUNT_LABEL,
    dueDate: Constants.INVOICE_DUE_DATE_LABEL,
  };
  const [errors, setErrors] = useState({});
  const [confirmationLineItems, setConfirmationLineItems] = useState([
    { quantity: inputFields.quantity, description: inputFields.description },
  ]);

  useEffect(() => {
    let { invoiceNumber, dueDate, ...savedInputFields } = inputFields;
    setDraftInvoice((prevState) => ({ ...prevState, ["simpleInvoice"]: savedInputFields }));
  }, [inputFields]);

  function handleChange(e, fieldName: string) {
    const newText = e.target.value;
    setInputFields((prevState) => {
      const updatedState = { ...prevState, [fieldName]: newText };
      setConfirmationLineItems([{ quantity: updatedState.quantity, description: updatedState.description }]);
      return updatedState;
    });
  }

  function handleBlurValidate(fieldName: string) {
    return new Promise((resolve) => {
      let errorMessage = "";

      if (!inputFields[fieldName]) {
        errorMessage = inputToLabelMap[fieldName] + Constants.INVOICE_ERROR_EMPTY_TEXT;
      } else {
        if (fieldName === "name" && inputFields[fieldName].length > Limits.CUSTOMER_NAME_LENGTH_LIMIT)
          errorMessage = Constants.INVOICE_ERROR_NAME_LENGTH_EXCEEDED;
        else if (fieldName === "email") {
          if (!validateField(fieldName, inputFields[fieldName])) {
            errorMessage = inputToLabelMap[fieldName] + Constants.INVOICE_ERROR_INVALID_TEXT;
          } else if (inputFields[fieldName].length > Limits.CUSTOMER_EMAIL_LENGTH_LIMIT) {
            errorMessage = Constants.INVOICE_ERROR_EMAIL_LENGTH_EXCEEDED;
          }
        } else if (fieldName === "amount") {
          if (!validateField(fieldName, inputFields[fieldName].replace(/,/g, ""))) {
            errorMessage = inputToLabelMap[fieldName] + Constants.INVOICE_ERROR_INVALID_TEXT;
          } else if (Number(inputFields[fieldName].replace(/,/g, "")) > Limits.FLOAT_LIMIT) {
            errorMessage = Constants.INVOICE_ERROR_AMOUNT_LIMIT_EXCEEDED;
          }
        } else if (fieldName === "invoiceNumber") {
          if (inputFields[fieldName].length > 20) errorMessage = Constants.INVOICE_ERROR_ID_LIMIT_EXCEEDED;
          else if (!validateField(fieldName, inputFields[fieldName]))
            errorMessage = Constants.INVOICE_ERROR_ID_INVALID_CHARS;
        } else if (fieldName === "description" && inputFields.description.length > Limits.DESCRIPTION_LENGTH_LIMIT) {
          errorMessage = Constants.INVOICE_ERROR_CHAR_LIMIT_EXCEEDED;
        } else if (fieldName === "dueDate") {
          if (!isValid(parse(inputFields[fieldName], "dd MMM yyyy", new Date()))) {
            setInputFields({ ...inputFields, [fieldName]: "" });
          }
        }
      }

      setErrors((prevState) => {
        return { ...prevState, [fieldName]: errorMessage };
      });
      resolve(errorMessage);
    });
  }

  function handleBlurFormat(fieldName: string, input: string) {
    handleBlurValidate(fieldName).then((errorMessage) => {
      if (errorMessage === "") {
        setInputFields({ ...inputFields, [fieldName]: formatAmount(input) });
      }
    });
  }

  async function validateAllFields() {
    const parentErrorPromises = Object.keys(inputFields).map((field) => handleBlurValidate(field));
    const parentErrorResults = await Promise.all(parentErrorPromises);

    const parentErrors = Object.fromEntries(
      Object.keys(inputFields).map((field, index) => [field, parentErrorResults[index]])
    );

    return Object.values(parentErrors).every((value) => value === "");
  }

  async function handleDraft(e) {
    e.preventDefault();
    let parentErrorsEmpty = await validateAllFields();

    if (parentErrorsEmpty) {
      new Promise(function (resolve, reject) {
        createInvoice(inputFields, [], resolve, reject);
      }).then(
        () => success(setInvoice, setDraftInvoice, "", [], [], "", navigate, true),
        (errorReason) => failure(errorReason)
      );
    }
  }

  async function handleSubmit(e, inputFields) {
    e.preventDefault();
    let parentErrorsEmpty = await validateAllFields();

    if (parentErrorsEmpty) {
      new Promise(function (resolve, reject) {
        createInvoice(inputFields, [], resolve, reject);
      }).then(
        () =>
          success(
            setInvoice,
            setDraftInvoice,
            rootPath,
            inputFields,
            confirmationLineItems,
            "$" + calculateTotal(inputFields.amount, inputFields.quantity),
            navigate,
            true
          ),
        (errorReason) => failure(errorReason)
      );
    }
  }

  return (
    <div>
      <Label className="visa-invoice-label-font"> {Constants.INVOICE_CUSTOMER_NAME_LABEL} </Label>
      <InputContainer>
        <Input
          required
          type="text"
          value={inputFields.name}
          onChange={(e) => handleChange(e, "name")}
          onBlur={() => handleBlurValidate("name")}
          aria-invalid={!!errors["name"]}
        />
      </InputContainer>
      {errors["name"] ? (
        <InputError errorText={errors["name"]} />
      ) : (
        <div className="visa-invoice-standard-spacer"></div>
      )}

      <Label className="visa-invoice-label-font"> {Constants.INVOICE_CUSTOMER_EMAIL_LABEL} </Label>
      <InputContainer>
        <Input
          required
          type="text"
          value={inputFields.email}
          onChange={(e) => handleChange(e, "email")}
          onBlur={() => handleBlurValidate("email")}
          aria-invalid={!!errors["email"]}
        />
      </InputContainer>
      {errors["email"] ? (
        <InputError errorText={errors["email"]} />
      ) : (
        <div className="visa-invoice-standard-spacer"></div>
      )}

      <Label className="visa-invoice-label-font"> {Constants.INVOICE_NUMBER_LABEL} </Label>
      <InputContainer>
        <Input
          required
          type="text"
          value={inputFields.invoiceNumber}
          onChange={(e) => handleChange(e, "invoiceNumber")}
          onBlur={() => handleBlurValidate("invoiceNumber")}
          aria-invalid={!!errors["invoiceNumber"]}
        />
      </InputContainer>
      {errors["invoiceNumber"] ? (
        <InputError errorText={errors["invoiceNumber"]} />
      ) : (
        <div className="visa-invoice-standard-spacer"></div>
      )}

      <Label className="visa-invoice-label-font">
        {Constants.INVOICE_DESCRIPTION_LABEL} &nbsp; <InfoIcon></InfoIcon>
      </Label>
      <InputContainer>
        <TextareaAutosize
          className="v-input visa-invoice-auto-resize-input"
          placeholder={Constants.INVOICE_DESCRIPTION_PLACEHOLDER_TEXT}
          value={inputFields.description}
          onChange={(e) => handleChange(e, "description")}
          onBlur={() => handleBlurValidate("description")}
          aria-invalid={!!errors["description"]}
        />
      </InputContainer>
      <InputMessage
        style={errors["description"] ? { paddingBottom: 10 } : {}}
        className="visa-invoice-description-message"
      >
        <div>
          {errors["description"] ? (
            <InputError errorText={errors["description"]} />
          ) : (
            <div style={{ paddingBottom: 25 }}></div>
          )}
        </div>
        <div className="visa-input-description-char-font">{inputFields.description.length} / 400</div>
      </InputMessage>

      <div className="visa-invoice-date-grid">
        <div>
          <Label className="visa-invoice-label-font"> {Constants.INVOICE_AMOUNT_LABEL} </Label>
          <InputContainer>
            <p> $ </p>
            <Input
              required
              value={inputFields.amount}
              onChange={(e) => handleChange(e, "amount")}
              onBlur={() => {
                handleBlurFormat("amount", inputFields.amount);
              }}
              aria-invalid={!!errors["amount"]}
            />
          </InputContainer>
          {errors["amount"] ? (
            <InputError errorText={errors["amount"]} />
          ) : (
            <div className="visa-invoice-end-spacer"></div>
          )}
        </div>
        <div>
          <Label className="visa-invoice-label-font"> {Constants.INVOICE_DUE_DATE_LABEL} </Label>
          <InputContainer>
            <Input
              required
              value={inputFields.dueDate}
              onChange={(e) => handleChange(e, "dueDate")}
              onBlur={() => handleBlurValidate("dueDate")}
              aria-invalid={!!errors["dueDate"]}
            />
            {inputFields.dueDate != "" ? (
              <Button
                buttonSize="small"
                iconButton
                colorScheme="tertiary"
                onClick={() => setInputFields({ ...inputFields, ["dueDate"]: "" })}
              >
                <ClearAltTiny className="visa-icons-size" />
              </Button>
            ) : null}
            <Button
              onClick={() => setIsCalendarOpen(!isCalendarOpen)}
              buttonSize="small"
              iconButton
              colorScheme="tertiary"
            >
              <CalendarTiny className="visa-icons-size" />
            </Button>
          </InputContainer>
          {isCalendarOpen && (
            <DatePicker
              selected={inputFields.dueDate ? parse(inputFields.dueDate, "dd MMM yyyy", new Date()) : ""}
              onChange={(date) => {
                setInputFields({ ...inputFields, dueDate: format(date, "dd MMM yyyy") });
                setIsCalendarOpen(false);
              }}
              dateFormat="dd MMM yyyy"
              inline
            />
          )}
          {errors["dueDate"] ? (
            <InputError errorText={errors["dueDate"]} />
          ) : (
            <div className="visa-invoice-end-spacer"></div>
          )}
        </div>
      </div>

      <div className="visa-invoice-total-display">
        <p> {Constants.INVOICE_TOTAL_DUE.toUpperCase()} </p>
        <p>{"$" + calculateTotal(inputFields.amount, inputFields.quantity)}</p>
      </div>
      <div className="visa-invoice-large-spacer"></div>

      <Button
        className="visa-invoice-button"
        onClick={() => {
          setInputFields((prevState) => {
            const updatedState = { ...prevState, ["invoiceMode"]: "CREATED" };
            handleSubmit(event, updatedState);
            return updatedState;
          });
        }}
      >
        {Constants.INVOICE_INSERT_BUTTON_TEXT}
      </Button>
      {/* UNCOMMENT BELOW CODE TO ENABLE DRAFT SAVE BUTTON ON FRONTENT
      <div className="visa-invoice-standard-spacer"></div>
      <Button colorScheme="secondary" className="visa-invoice-button" onClick={handleDraft}>
        {Constants.INVOICE_DRAFT_BUTTON_TEXT}
      </Button> */}
    </div>
  );
};

export default SimpleInvoiceComponent;
