import * as React from "react";
import Cookies from "js-cookie";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Progress from "@taskpane/components/Progress";
import * as outlook from "@taskpane/components/Outlook.App";
import { Route, Routes } from "react-router-dom";
import AppHeader from "@taskpane/components/AppHeader";
import NoMatch from "@taskpane/components/NoMatch";
import DashboardContainer from "@taskpane/pages/Dashboard/DashboardContainer";
import EnvironmentSelectionPage from "@taskpane/pages/SelectEnvironment/EnvironmentSelectionPage";
import WelcomePage from "@taskpane/pages/Welcome/WelcomePage";
import InvoiceDetails from "@taskpane/pages/Details/InvoiceDetails";
import { ConfirmationPage } from "@taskpane/pages/Confirmation/ConfirmationPage";
import { SharedDetailsProvider } from "@taskpane/components/SharedDetailsContext";
import { SharedInvoiceStateProvider } from "./SharedInvoiceStateContext";
import { SharedCreatedInvoiceProvider } from "./SharedCreatedInvoiceContext";
import * as Constants from "@constants/Constants";
import NavBar from "@taskpane/components/NavBar";
import LoadingComponent from "@taskpane/components/LoadingComponent";
import { ErrorDialog } from "@taskpane/components/ErrorDialog";
import { useNavigate } from "react-router-dom";
import { PrivateRoute } from "@taskpane/components/PrivateRoute";
import { jwtDecode } from "jwt-decode";
import { EnvironmentTypes } from "@constants/environmentEnum";
import { login } from "@utilities/invoicingAPI";
/* global Office, require */

export interface AppProps {
  title: string;
  isOfficeInitialized: boolean;
}

const queryClient = new QueryClient();

export default class App extends React.Component<AppProps> {
  errorDialogRef: React.RefObject<HTMLDialogElement>;
  isFirstOpen: boolean;
  isAuthenticated: boolean;
  rootPath: string;
  isPlatfromSupported: boolean;
  isBusy: boolean;
  constructor(props, context) {
    super(props, context);
    this.errorDialogRef = React.createRef<HTMLDialogElement>();
    this.state = {
      environment: localStorage.getItem(Constants.ENVIRONMENT_LOCAL_STORAGE)
        ? localStorage.getItem(Constants.ENVIRONMENT_LOCAL_STORAGE)
        : "Sandbox",
      recipientName: "",
      recipientEmail: "",
      isBusy: false,
      isPlatformSupported: true,
      isAuthenticated: false,
      displayGoLiveLink: true
    };
    this.isFirstOpen = true;
    this.rootPath = location.pathname;
    this.isPlatfromSupported = true;
  }
  
  componentDidMount(): void {
    if (Office.context.platform != null && Office.context.platform+"" =="OfficeOnline") {
      this.isPlatfromSupported = false;
      this.setState({
        ...this.state,
        recipientName: Constants.TEST_RECIPIENT_NAME,
        recipientEmail: Constants.TEST_RECIPIENT_EMAIL,
        isPlatformSupported : this.isPlatfromSupported
      });
    }
    
    if (Office.context.mailbox != null) {
      const toAsync = Office.context.mailbox.item.to;
      toAsync.getAsync((asyncRes) => {
        if (asyncRes.status === Office.AsyncResultStatus.Succeeded) {
          const toRecipients = asyncRes.value;
          if (toRecipients && toRecipients.length > 0) {
            const firstRecipient = toRecipients[0].displayName;
            const firstEmail = toRecipients[0].emailAddress;
            this.setState({ ...this.state, recipientName: firstRecipient, recipientEmail: firstEmail, isBusy: true});
            login(this.setAuthenticationParameters)
          }
        }
      });
    } else {
      this.setState({
        ...this.state,
        recipientName: Constants.TEST_RECIPIENT_NAME,
        recipientEmail: Constants.TEST_RECIPIENT_EMAIL
      }); // This parent if/else null check is present only for testing purposes to make sure app runs on browser
    }
    
    if (localStorage.getItem(Constants.FIRST_OPEN_LOCAL_STORAGE)) {
      this.isFirstOpen = false;
    }

    if (this.errorDialogRef.current) {
      this.errorDialogRef.current.showModal();
    }

    window.addEventListener("storage", () => {
      var selectedEnv: string = localStorage.getItem(Constants.ENVIRONMENT_LOCAL_STORAGE);
      this.setState({ ...this.state, environment: selectedEnv });
    });

    window.addEventListener("first_ack", () => {
      localStorage.setItem(Constants.FIRST_OPEN_LOCAL_STORAGE, "false");
    });
  }

  click = async () => {
    switch (Office.context.host) {
      case Office.HostType.Outlook: {
        const outlookApp = new outlook.default(this.props, this.context);
        return outlookApp.click();
      }
    }
  };

  setAuthenticationParameters = (isBusy, isAuthenticated) => {
    this.setState({
      ...this.state,
      isBusy: isBusy,
      isAuthenticated: isAuthenticated
    });
  }
  
  displayGoLiveLink = (doDisplay) => {
    this.setState({
      ...this.state,
      displayGoLiveLink : doDisplay
    });
  }
  
  render() {
    const { title, isOfficeInitialized } = this.props;

    if (!isOfficeInitialized) {
      return (
        <Progress
          title={title}
          logo={require("./../../../assets/Logo-VisaInvoice.png")}
          message="Please sideload your addin to see app body."
        />
      );
    }

    if (!this.state["recipientName"] || !this.state["isPlatformSupported"]) {
      let headerString = !this.state["isPlatformSupported"] ? Constants.PLATFORM_NOT_SUPPORTED_HEADER : Constants.NO_RECIPIENTS_ERROR_HEADER
      let contentString = !this.state["isPlatformSupported"] ? Constants.PLATFORM_NOT_SUPPORTED_TEXT : Constants.NO_RECIPIENTS_ERROR_TEXT
      return <ErrorDialog headerText = {headerString} contentText = {contentString} type = "error" errorDialogRef={this.errorDialogRef} />;
    }

    if (this.state['isBusy']) {
      return <LoadingComponent text="Loading App..."></LoadingComponent>;
    }
    
    return (
      <QueryClientProvider client={queryClient}>
        <SharedInvoiceStateProvider>
          <>
            {/*uncomment NavBar during development to easily move between pages.*/}
            {/*<NavBar rootPath={this.rootPath} />*/}
            <AppHeader title={Constants.VISA_TITLE} environment={this.state["environment"]} doDisplayGoLiveLink={this.state["displayGoLiveLink"]}/>
            <Routes>
              {/* This is the first page that will be shown to the user. It will only be shown once.*/}
              <Route path={this.rootPath} element={<PrivateRoute rootPath={this.rootPath} isAuthenticated={this.state['isAuthenticated']} />} />
              <Route
                path={this.rootPath + "dashboard"}
                element={
                  <SharedDetailsProvider>
                    <SharedCreatedInvoiceProvider>
                      <DashboardContainer
                        recipient={this.state["recipientName"]}
                        customerId={this.state["recipientEmail"]}
                        rootPath={this.rootPath}
                      />
                    </SharedCreatedInvoiceProvider>
                  </SharedDetailsProvider>
                }
              />
              {/* This is the page that will be shown to the user every time they open the add-in.*/}
              <Route
                path={this.rootPath + "environment"}
                element={<EnvironmentSelectionPage rootPath={this.rootPath} setGoLiveLinkVisibility={this.displayGoLiveLink} />}
              />
              <Route
                path={this.rootPath + "details/:id"}
                element={
                  <SharedDetailsProvider>
                    <SharedCreatedInvoiceProvider>
                      <InvoiceDetails recipient={this.state["recipientName"]} rootPath={this.rootPath} />
                    </SharedCreatedInvoiceProvider>
                  </SharedDetailsProvider>
                }
              />
              <Route
                path={this.rootPath + "confirmation"}
                element={
                  <SharedDetailsProvider>
                    <SharedCreatedInvoiceProvider>
                      <ConfirmationPage rootPath={this.rootPath} />
                    </SharedCreatedInvoiceProvider>
                  </SharedDetailsProvider>
                }
              />
              <Route path="*" element={<NoMatch />} />
            </Routes>
          </>
        </SharedInvoiceStateProvider>
      </QueryClientProvider>
    );
  }
}