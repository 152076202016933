import React from "react";
import * as Constants from "@constants/Constants";
import "@styles/details-styles.css";
import "@styles/dashboard-styles.css";
import { EditTiny } from "@visa/nova-icons/react/visa";
import { Link } from "react-router-dom";
import { useDetails } from "@taskpane/components/SharedDetailsContext";

const InvoiceEditButton = ({ invoiceDetails, lineItems, rootPath }) => {
  const { setDetails } = useDetails();

  const handleEditClick = () => {
    let { paymentLink, ...filteredDetails } = invoiceDetails;
    setDetails((prevState) => ({ ...prevState, ["listInvoice"]: filteredDetails }));
  };

  return (
    <Link
      to={rootPath + "dashboard?tab=2"}
      onClick={handleEditClick}
      style={{ textDecoration: "none" }}
    >
      <div className="v-flex v-gap-3 v-align-items-center">
        <EditTiny className="visa-icons-size" />
        <p className="visa-invoice-edit-text"> {Constants.EDIT_TEXT} </p>
      </div>
    </Link>
  );
};

export default InvoiceEditButton;
