import { formatDateFromApis, formatAmount, formatDateFromUI } from "@utilities/textFormat";
import { pasteLinkinEmail } from "@utilities/outlookClient";
import * as Constants from "@constants/Constants";
import utcToZonedTime from "date-fns-tz/utcToZonedTime";
import { format } from "date-fns";
import { getIdentityToken } from "@utilities/outlookClient";

const baseURL: string = `${Constants.INVOICING_API_URL}api/invoices`;

export var fnSignOut: any

export const setSignOutCallBack = async (signOutFunction: () => void) => {
  fnSignOut = signOutFunction
}

export const getInvoiceDetails = async (invoiceId: string) => {
  const identityToken = await getIdentityToken().catch((error) => {
    throw new Error(error);
  })
  const endpoint: string = baseURL + `/${invoiceId}`;
  try {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "msft-authorization": "Bearer "+identityToken,
        "environment": localStorage.getItem("environment")
      },
    };

    const response = await fetch(endpoint, requestOptions);
    if(response.status == 401){
      fnSignOut()
    }
    const data = await response.json();

    if (response.ok) {
      data.datePaid = data.datePaid ? formatDateFromApis(data.datePaid) : "n/a";
      data.dueDate = format(utcToZonedTime(new Date(data.dueDate), "Etc/UTC"), "dd MMM yyyy");
      data.invoiceCreationDate = data.invoiceCreationDate
        ? formatDateFromApis(data.invoiceCreationDate)
        : "n/a";
      data.lineItems.forEach((lineItem) => {
        lineItem.amount = formatAmount(lineItem.amount);
        lineItem.discountAmount = lineItem.discountAmount ? formatAmount(lineItem.discountAmount) : "";
        lineItem.discountPercent = lineItem.discountPercent ? formatAmount(lineItem.discountPercent) : "";
        lineItem.taxAmount = lineItem.taxAmount ? formatAmount(lineItem.taxAmount) : "";
        lineItem.taxRate = lineItem.taxRate ? lineItem.taxRate : "";
        lineItem.totalAmount = lineItem.totalAmount ? formatAmount(lineItem.totalAmount) : "";
      });
      data.totalAmount = formatAmount(data.totalAmount);
      data.shippingAmount = data.shippingAmount ? formatAmount(data.shippingAmount) : "";
      data.shippingTax = data.shippingTax ? data.shippingTax : "";
      return data;
    }

    throw new Error(data.detailmessage.message);
  } catch (error) {
    throw new Error(error);
  }
};

export const getListOfInvoices = async (offset: string, customer: string) => {
  const identityToken = await getIdentityToken().catch((error) => {
    throw new Error(error)
  })
  const endpoint: string = `${baseURL}?` + new URLSearchParams({ offset: offset, customer: customer }).toString();
  try {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "msft-authorization":  "Bearer "+identityToken,
        "environment": localStorage.getItem("environment")
      },
    };

    const response = await fetch(endpoint, requestOptions);
    if(response.status == 401){
      fnSignOut()
    }
    const data = await response.json();
    const cleanedData = { DRAFT: [], OVERDUE: [], UNPAID: [], PAID: [] };

    if (response.ok) {
      data.invoicelist.forEach((invoice) => {
        if (invoice.status != "PAID" && invoice.status != "DRAFT") {
          const now = new Date();
          now.setHours(0, 0, 0, 0);
          const dueDate = utcToZonedTime(new Date(invoice.dueDate), "Etc/UTC");
          if (now.getTime() > dueDate.getTime()) {
            invoice.status = "OVERDUE";
          } else {
            invoice.status = "UNPAID";
          }
        }
        cleanedData[invoice.status].push({
          invoiceNo: invoice.id,
          amount: "$" + formatAmount(invoice.totalAmount),
          dueDate: format(utcToZonedTime(new Date(invoice.dueDate), "Etc/UTC"), "dd MMM yyyy"),
        });
      });
    } else {
      if(data.detailmessage.status == Constants.NO_DATA_FOUND_STATUS){
        return [];
      } else {
        throw new Error(data.detailmessage.message);
      }
    }

    let invoices = [];
    ["OVERDUE", "UNPAID", "PAID", "DRAFT"].forEach((status) => {
      cleanedData[status].forEach((invoice) => {
        invoices.push({
          ...invoice,
          status,
        });
      });
    });

    return invoices;
  } catch (error) {
    throw new Error(error);
  }
};

export const createInvoice = async (inputFields, listItems = [], resolve: Function, reject: Function) => {
  const identityToken = await getIdentityToken().catch((error) => {
    reject(error);
  })
  var myHeaders = new Headers();
  myHeaders.append("msft-authorization",  "Bearer "+identityToken);
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("environment", localStorage.getItem("environment"))

  let lineItems = [];
  let totalAmount = "";

  if (listItems.length >= 1) {
    for (let i = 0; i < listItems.length; i++) {
      let lineItem: { [key: string]: any } = {};
      lineItem.productSku = (i + 1).toString();
      lineItem.productName = listItems[i].productName;
      lineItem.quantity = listItems[i].quantity;
      lineItem.unitPrice = parseFloat(listItems[i].amount.replace(/,/g, "")).toString();
      lineItem.discountAmount = listItems[i].discountAmount;
      lineItem.discountPercent = listItems[i].discountPercent;
      lineItem.taxRate = listItems[i].taxRate;
      lineItem.taxAmount = listItems[i].taxAmount;
      lineItem.totalAmount = parseFloat(listItems[i].totalAmount.replace(/,/g, "")).toString();
      lineItems.push(lineItem);
    }
    totalAmount = parseFloat(inputFields.totalAmount.replace(/,/g, "")).toString();
  } else {
    lineItems.push({
      productSku: "1",
      productName: "Item 1",
      quantity: inputFields.quantity,
      unitPrice: parseFloat(inputFields.amount.replace(/,/g, "")).toString(),
      discountAmount: "",
      discountPercent: "",
      taxRate: "",
      taxAmount: "",
      totalAmount: parseFloat(inputFields.amount.replace(/,/g, "")).toString(),
    });
    totalAmount = lineItems[0].totalAmount;
  }

  var body = {
    customerName: inputFields.name,
    customerEmail: inputFields.email,
    invoiceNumber: inputFields.invoiceNumber,
    description: inputFields.description,
    dueDate: formatDateFromUI(inputFields.dueDate),
    lineItems: lineItems,
    shipping:
      inputFields.hasOwnProperty("shippingAmount") && inputFields.shippingAmount ? inputFields.shippingAmount : "0.00",
    shippingTax: inputFields.hasOwnProperty("shippingTax") ? inputFields.shippingTax : "",
    totalAmount: totalAmount,
    invoiceMode: inputFields.invoiceMode,
  };

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(body),
  };

  const createRequest = await fetch(baseURL, requestOptions);
  const response = await createRequest.json();
  if(createRequest.status == 401){
    reject(response.detailmessage.message);
    fnSignOut()
  }
  if (response.state == "CREATED") {
    pasteLinkinEmail(response.paymentLink, inputFields.name);
    resolve();
  } else if (response.state === "DRAFT") resolve();
  reject(response.detailmessage.message);
};

export const updateInvoice = async (inputFields, listItems = [], resolve: Function, reject: Function) => {
  const identityToken = await getIdentityToken().catch((error) => {
    reject(error);
  })
  var myHeaders = new Headers();
  myHeaders.append("msft-authorization",  "Bearer "+identityToken);
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("environment", localStorage.getItem("environment"))
  
  let lineItems = [];
  let totalAmount = "";

  for (let i = 0; i < listItems.length; i++) {
    let lineItem: { [key: string]: any } = {};
    lineItem.productSku = (i + 1).toString();
    lineItem.productName = listItems[i].productName;
    lineItem.quantity = listItems[i].quantity;
    lineItem.unitPrice = parseFloat(listItems[i].amount.replace(/,/g, "")).toString();
    lineItem.discountAmount = listItems[i].discountAmount;
    lineItem.discountPercent = listItems[i].discountPercent;
    lineItem.taxRate = listItems[i].taxRate;
    lineItem.taxAmount = listItems[i].taxAmount;
    lineItem.totalAmount = parseFloat(listItems[i].totalAmount.replace(/,/g, "")).toString();
    lineItems.push(lineItem);
  }
  totalAmount = parseFloat(inputFields.totalAmount.replace(/,/g, "")).toString();

  var body = {
    customerName: inputFields.name,
    customerEmail: inputFields.email,
    invoiceNumber: inputFields.invoiceNumber,
    description: inputFields.description,
    dueDate: formatDateFromUI(inputFields.dueDate),
    lineItems: lineItems,
    shipping:
      inputFields.hasOwnProperty("shippingAmount") && inputFields.shippingAmount ? inputFields.shippingAmount : "0.00",
    shippingTax: inputFields.hasOwnProperty("shippingTax") ? inputFields.shippingTax : "",
    totalAmount: totalAmount,
    invoiceMode: inputFields.invoiceMode,
  };

  var requestOptions = {
    method: "PUT",
    headers: myHeaders,
    body: JSON.stringify(body),
  };

  const createRequest = await fetch(baseURL, requestOptions);
  const response = await createRequest.json();
  if(createRequest.status == 401){
    reject(response.detailmessage.message);
    fnSignOut()
  }
  if (response.state == "UPDATED" || response.state === "CREATED") {
    pasteLinkinEmail(response.paymentLink, inputFields.name);
    resolve();
  } else if (response.state === "DRAFT") resolve();
  reject(response.detailmessage.message);
};

export const login = async (setLoginParameters) => {
  const identityToken = await getIdentityToken().catch((error) => {
    setLoginParameters(false, false)
  })
  const loginEndpoint: string = `${Constants.INVOICING_API_URL}api/login`
  var myHeaders = new Headers();
  myHeaders.append("msft-authorization", "Bearer " + identityToken);
  myHeaders.append("environment", localStorage.getItem("environment"));

  var requestOptions = {
    method: 'GET',
    headers: myHeaders
  };

  const authenticationResponse = await fetch(loginEndpoint, requestOptions);
  if(authenticationResponse.ok){
    setLoginParameters(false, true)
  } else {
    setLoginParameters(false, false)
  }
}
export const logout = async(resolve: Function, reject: Function) => {
  const identityToken = await getIdentityToken().catch((error) => {
    reject(error);
  });
  var myHeaders = new Headers();
  myHeaders.append("msft-authorization",  "Bearer "+identityToken);
  myHeaders.append("environment", localStorage.getItem("environment"));
  var requestOptions = {
    method: 'POST',
    headers: myHeaders
  };
  const logoutEndpoint: string = `${Constants.INVOICING_API_URL}api/logout`
  const logoutResponse = await fetch(logoutEndpoint, requestOptions);
  if(logoutResponse.ok){
    resolve();
  } else {    
    reject(Constants.LOGOUT_FAILED_TEXT);
  }
}