import Banner from "@visa/nova-react/banner";
import BannerCloseButton from "@visa/nova-react/banner-close-button";
import BannerContent from "@visa/nova-react/banner-content";
import BannerIcon from "@visa/nova-react/banner-icon";
import Typography from "@visa/nova-react/typography";
import React, { useState } from "react";

export const ErrorBanner = () => {
  const [bannerState, setBannerState] = useState({
    isError: false,
    errorMessage: "",
  });
  var errorMessage;

  const handleClick = () => {
    setBannerState({
      isError: false,
      errorMessage: "",
    });
    localStorage.removeItem("error_message");
  };

  window.addEventListener("showbanner", () => {
    var errorMessageText: string = localStorage.getItem("error_message");
    setBannerState({
      isError: true,
      errorMessage: errorMessageText,
    });
  });
  return (
    <div>
      {bannerState.isError ? (
        <Banner messageType="error" style={{ position: "fixed", top: 0, left: 0, width: "100%", zIndex: 1000 }}>
          <BannerIcon />
          <BannerContent>
            <Typography>{bannerState.errorMessage}</Typography>
          </BannerContent>
          <BannerCloseButton id="error_banner_close" onClick={handleClick} />
        </Banner>
      ) : null}
    </div>
  );
};
