export const navigateSubmit = (rootPath: string, navigate: (path: string) => void) => {
  if (localStorage.getItem("error_message")) {
    localStorage.removeItem("error_message");
  }
  if (!rootPath) document.getElementById("dashboard_home").click();
  else navigate(rootPath + "confirmation");
};

export const success = (
  setInvoice: React.Dispatch<React.SetStateAction<object>>,
  setDraftInvoice: React.Dispatch<React.SetStateAction<object>>,
  rootPath: string,
  inputFields,
  confirmationLineItems,
  totalAmount: string,
  navigate: (path: string) => void,
  simple: boolean
) => {
  if (rootPath) {
    setInvoice({
      confirmationLineItems: confirmationLineItems,
      totalAmount: totalAmount,
      dueDate: inputFields.dueDate,
    });
  }
  simple
    ? setDraftInvoice((prevState) => ({ ...prevState, ["simpleInvoice"]: {} }))
    : setDraftInvoice((prevState) => ({ ...prevState, ["listInvoice"]: { inputFields: {}, lineItemStates: [] } }));
  navigateSubmit(rootPath, navigate);
};

export const failure = (errorReason: string) => {
  localStorage.setItem("error_message", errorReason);
  window.dispatchEvent(new Event("showbanner"));
  window.scrollTo(0, 0);
};
